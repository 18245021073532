import { ReactElement, useState } from "react";
import { StartupContactDTO } from "../../../Types/StartupContact";
import { IconButton, Stack, styled } from "@mui/material";
import theme from "../../../theme";
import { EditOutlined } from "@mui/icons-material";
import ContactName from "./ContactName";
import ContactCardContacts from "./ContactCardContacts";
import EditStartupContactModal from "../StartupComponents/EditStartupContactModal";

const ContactCardWrapper = styled(Stack, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $color = "white" }: { $color?: "gray" | "white" }) => ({
  gap: theme.spacing(3),
  padding: theme.spacing(3),
  position: "relative",
  backgroundColor:
    $color === "white"
      ? theme.palette.surface.primary.main
      : theme.palette.background.default,
  boxShadow: $color === "white" ? theme.boxShadows[2] : "none",
  borderRadius: theme.shape.radius.minimal,
}));

interface StartupContactCardProps {
  contact: StartupContactDTO;
  startupId?: number;
  editable?: boolean;
  color?: "white" | "gray";
  handleSave: () => void;
}

export default function StartupContactCard({
  color,
  editable,
  contact,
  startupId,
  handleSave,
}: StartupContactCardProps): ReactElement {
  const [editModalOpen, setEditModalOpen] = useState(false);

  return (
    <>
      <ContactCardWrapper $color={color}>
        <ContactName
          name={contact?.name}
          title={contact?.title}
          orientation="vertical"
        />
        <ContactCardContacts contact={contact} />
        {editable && (
          <IconButton
            size="small"
            sx={{ position: "absolute", right: theme.spacing(1) }}
            onClick={() => {
              setEditModalOpen(true);
            }}
          >
            <EditOutlined
              fontSize="small"
              htmlColor={theme.palette.icon.primary}
            />
          </IconButton>
        )}
      </ContactCardWrapper>
      {editModalOpen && (
        <EditStartupContactModal
          contact={contact}
          handleSave={handleSave}
          modalOpen={editModalOpen}
          setModalOpen={setEditModalOpen}
          startupId={startupId}
        />
      )}
    </>
  );
}
