import { useEffect, useState } from "react";
import { clientSpecifications } from "../Constants/ClientSpecifications";
import { extractClientPrefixFromUrl } from "../utils";

interface useClientReturns {
  logo: string;
  background: string;
  isExternalUrl: boolean;
  hero: string;
  loginPageLogo: string;
  activeClientUrl: string;
}

function useClient(): useClientReturns {
  const [activeLogo, setActiveLogo] = useState("");
  const [activeBackground, setActiveBackground] = useState("");
  const [activeClientUrl, setActiveClientUrl] = useState("");
  const [activeHero, setActiveHero] = useState("");
  const [activeLoginPageLogo, setActiveLoginPageLogo] = useState("");

  useEffect(() => {
    const clientPrefix = extractClientPrefixFromUrl();

    updateLogoAndBackground(clientPrefix);
    setActiveClientUrl(clientPrefix);
  }, []);

  const updateLogoAndBackground = (clientUrl: string) => {
    const environment =
      process.env.NODE_ENV === "development" ? "development" : "production";
    let baseS3Url = "";
    if (environment === "development") {
      baseS3Url = "https://vclms-frontend.s3.eu-central-1.amazonaws.com";
    } else {
      baseS3Url = "https://vclms-frontend-prod.s3.eu-central-1.amazonaws.com";
    }

    const logo = clientSpecifications[clientUrl].logo;
    const logoWhite = clientSpecifications[clientUrl].logoWhite;
    const background = clientSpecifications[clientUrl].background;
    const hero = clientSpecifications[clientUrl].hero;
    const loginPageLogo =
      clientSpecifications[clientUrl].loginPageLogo ||
      clientSpecifications[clientUrl].logo;

    if (logoWhite) setActiveLogo(`${baseS3Url}/logos_white/${logoWhite}`);
    else setActiveLogo(logo ? `${baseS3Url}/${logo}` : "");
    setActiveBackground(background ? `${baseS3Url}/${background}` : "");
    setActiveHero(hero ? `${baseS3Url}/hero/${hero}` : "");
    setActiveLoginPageLogo(
      loginPageLogo ? `${baseS3Url}/${loginPageLogo}` : activeLogo
    );
  };

  return {
    logo: activeLogo,
    background: activeBackground,
    isExternalUrl: activeClientUrl !== "27pilots",
    hero: activeHero,
    loginPageLogo: activeLoginPageLogo,
    activeClientUrl: activeClientUrl,
  };
}

export default useClient;
