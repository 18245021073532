import { ReactElement, useContext, useReducer, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, CardProps } from "@mui/material";
import { NavLink } from "react-router-dom";
import { LeadProject } from "../../Types/LeadProject";
import { formatDate, formatStatusDate, nameToInitials } from "../../utils";
import RocketLaunchOutlinedIcon from "@mui/icons-material/RocketLaunchOutlined";
import {
  PhaseDaysWrapper,
  ProjectOwnerAvatar,
  ProjectStatusIcon,
  StyledProjectCard,
  VentureClientNameWrapper,
} from "./ProjectKanbanCard";
import theme from "../../theme";
import useRoles from "../../Hooks/useRoles";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { Reducer } from "react";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
import { enqueueSnackbar } from "notistack";
import Bookmark from "../UI/Bookmark";
import LeadProjectDetailsModal from "../UI/Modals/ProjectDetailsModal/LeadProjectDetailsModal";

interface Props extends CardProps {
  leadProject: LeadProject;
  refreshLeadProjectData: (preventLoading?: boolean) => void;
}

function ProjectLeadKanbanCard(props: Props): ReactElement {
  const { setGlobalLoader } = useContext(GlobalLoaderContext);

  const {
    leadProject: defaultLeadProject,
    refreshLeadProjectData,
    ...defaultProps
  } = props;
  const {
    projectOwner,
    status,
    ventureClient,
    dateCreated,
    name,
    opportunities,
  } = defaultLeadProject;

  const [leadProject, setLeadProject] = useReducer<
    Reducer<LeadProject, Partial<LeadProject>>
  >((state, newState) => ({ ...state, ...newState }), props.leadProject);

  const isArchivedOrOnhold = status === "archived" || status === "on hold";
  const formattedCreatedDate = dateCreated
    ? formatDate(new Date(dateCreated))
    : "";
  const numberOfStartups = opportunities.length || 0;
  const leadAge = calculateDaysBetweenDates(new Date(), new Date(dateCreated));

  function calculateDaysBetweenDates(date1: Date, date2: Date): number {
    const _date2 = new Date(date2);
    const differenceInMs: number = Math.abs(_date2.getTime() - date1.getTime());
    const daysDifference: number = Math.ceil(
      differenceInMs / (1000 * 60 * 60 * 24)
    );
    return daysDifference;
  }

  const updateLeadProject = () => {
    try {
      setGlobalLoader(true);
      LeadProjectsHttpService.updateLeadProject({
        id: leadProject.id,
        bookmarked: !leadProject.bookmarked,
      }).then(() => {
        refreshLeadProjectData(true);
      });
    } catch (error) {
      enqueueSnackbar("Something went wrong with updating lead project", {
        variant: "error",
      });
      setLeadProject({ bookmarked: leadProject.bookmarked });
    } finally {
      setGlobalLoader(false);
    }
  };

  const handleBookmark = () => {
    setLeadProject({ bookmarked: !leadProject.bookmarked });
    updateLeadProject();
  };

  return (
    <StyledProjectCard
      data-testid={`lead-project-card-${props.leadProject.id}`}
      $isArchivedOrOnhold={isArchivedOrOnhold}
      $isAdopted={false}
      {...defaultProps}
    >
      <Box display="flex" alignItems="center">
        <Bookmark
          bookmarked={props.leadProject.bookmarked}
          handleBookmark={handleBookmark}
        />
        <Box display="flex" alignItems="center" gap={1}>
          <ProjectOwnerAvatar>
            <Typography variant="overline">
              {nameToInitials(projectOwner.name)}
            </Typography>
          </ProjectOwnerAvatar>
          <Typography variant="body2" color="text.mediumEmphasis">
            {projectOwner.name}
          </Typography>
        </Box>
      </Box>

      <VentureClientNameWrapper>
        <Typography variant="subtitle2" color="text.mediumEmphasis">
          {ventureClient.name}
        </Typography>
      </VentureClientNameWrapper>

      <Box>
        <Typography
          variant="subtitle2"
          color={isArchivedOrOnhold ? "text.mediumEmphasis" : "text.primary"}
        >
          {name}
        </Typography>
        <Typography
          variant="body2"
          color={isArchivedOrOnhold ? "text.disabled" : "text.mediumEmphasis"}
        >
          {formattedCreatedDate}
        </Typography>
      </Box>

      <Box
        display="flex"
        gap={1}
        height="24px"
        color={isArchivedOrOnhold ? "text.disabled" : "text.primary"}
      >
        <Box gap={0.5} alignItems="center" display="flex">
          <RocketLaunchOutlinedIcon sx={{ fontSize: "13px" }} />
          <Typography
            variant="body2"
            data-testid={`nrOfStartups-${props.leadProject.id}`}
          >
            {numberOfStartups}
          </Typography>
        </Box>
      </Box>

      <Box display="flex" gap={1} alignItems="center">
        <ProjectStatusIcon status={status} />
        {!isArchivedOrOnhold ? (
          <PhaseDaysWrapper>
            <Typography>
              <Typography component="span" variant="subtitle2">
                {leadAge ?? "--"}
                {leadAge === 1 ? " day " : " days "}
              </Typography>
              in Lead Projects
            </Typography>
          </PhaseDaysWrapper>
        ) : (
          <Typography
            bgcolor={theme.palette.background.default}
            color="text.mediumEmphasis"
            p={theme.spacing(0.5, 1)}
            width="100%"
          >
            <Typography component="span" textTransform="capitalize">
              {status}
            </Typography>
            {status === "on hold" &&
              formatStatusDate(props.leadProject.onHoldDeadline, status)}
            {status === "archived" &&
              formatStatusDate(
                props.leadProject.leadProjectHistoryStatusChange[0]
                  ?.dateTriggered,
                status
              )}
          </Typography>
        )}
      </Box>
    </StyledProjectCard>
  );
}

export default function LeadKanbanCardUserBased(props: Props): ReactElement {
  const { isExternalUser } = useRoles();
  const [modalOpen, setModalOpen] = useState(false);

  return isExternalUser ? (
    <>
      <ProjectLeadKanbanCard
        {...props}
        onClick={() => setModalOpen(true)}
        sx={{
          cursor: "pointer",
        }}
      />
      {modalOpen && (
        <LeadProjectDetailsModal
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          leadProjectId={props.leadProject.id}
        />
      )}
    </>
  ) : (
    <NavLink to={`/lead-project/${props.leadProject.id}`}>
      <ProjectLeadKanbanCard {...props} />
    </NavLink>
  );
}
