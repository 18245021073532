import { NewReleasesOutlined, Verified } from "@mui/icons-material";
import { Badge, Box, styled, Tooltip, Typography } from "@mui/material";
import { ReactElement } from "react";
import useRoles from "../../../../Hooks/useRoles";
import { User } from "../../../../Types/User";
import { formatDate, getDateMonthsAfter } from "../../../../utils";

const StyledBadge = styled(Badge)(() => ({
  "& .MuiBadge-dot": {
    position: "relative",
    top: "5px",
    right: "9px",
    height: "6px",
    minWidth: "6px",
  },
}));

interface QualityVerificationIconProps {
  lastQualityCheckDate: Date | null;
  lastQualityCheckBy: User | null;
  isQualityChecked: boolean | null;
  hasExpired: boolean;
}

export default function QualityVerificationIcon({
  lastQualityCheckDate,
  lastQualityCheckBy,
  isQualityChecked,
  hasExpired,
}: QualityVerificationIconProps): ReactElement {
  const { isExternalUser } = useRoles();

  if (!lastQualityCheckDate) return <></>;

  const warningDate = getDateMonthsAfter(lastQualityCheckDate, 9);
  const isRunningOut =
    lastQualityCheckDate &&
    isQualityChecked &&
    warningDate < new Date() &&
    !hasExpired;

  const tooltipText = (
    <Box>
      {hasExpired ? (
        <Typography>Venture Client Readiness Status Needs Renewal</Typography>
      ) : isRunningOut ? (
        <Typography>Venture Client Readiness Status Expires Soon</Typography>
      ) : isQualityChecked === false ? (
        <Typography>Not Venture Client-ready</Typography>
      ) : isQualityChecked === true ? (
        <Typography>Venture Client-ready</Typography>
      ) : null}
      {lastQualityCheckBy && lastQualityCheckDate && (
        <Typography variant="body2">
          {!isExternalUser ? lastQualityCheckBy?.name + " - " : ""}
          {formatDate(lastQualityCheckDate)}
        </Typography>
      )}
    </Box>
  );

  return (
    <Tooltip title={tooltipText}>
      {hasExpired ? (
        <Verified color="disabled" data-testid="expired-icon" />
      ) : isRunningOut ? (
        <StyledBadge variant="dot" color="warning" data-testid="renewal-badge">
          <Verified color="success" data-testid="success-icon" />
        </StyledBadge>
      ) : isQualityChecked === false ? (
        <NewReleasesOutlined color="warning" data-testid="failed-icon" />
      ) : isQualityChecked === true ? (
        <Verified color="success" data-testid="success-icon" />
      ) : (
        <></>
      )}
    </Tooltip>
  );
}
