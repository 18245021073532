import { ReactElement, useState } from "react";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import { ReportGmailerrorred } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Collapse,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { stageScores } from "../../../../Constants/FunnelStages";
import theme from "../../../../theme";

const RuledOutContainer = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(0.5),
}));

const DescriptionContainer = styled(Stack)(() => ({
  gap: theme.spacing(0.5),
  padding: theme.spacing(0, 0, 0.5, 2.5),
}));

interface StartupRuledOutReasonProps {
  opportunity: StartupOpportunity;
}
export default function StartupRuledOutReason(
  props: StartupRuledOutReasonProps
): ReactElement {
  const { opportunity } = props;

  const [isExpanded, setIsExpanded] = useState(false);

  const projectStageScore = stageScores[opportunity.project.funnelStage];
  const isBeyondDiscover = projectStageScore > stageScores["discover"];
  const isBeyondAssess = projectStageScore > stageScores["assess"];

  const showDecisionRationale =
    isBeyondAssess && opportunity.assessmentDecision;

  const additionalRejectionReasons = opportunity.rejectionReasons.slice(1);

  const showMoreRejectionReasons =
    isBeyondDiscover &&
    !showDecisionRationale &&
    additionalRejectionReasons.length > 0;

  const moreContent =
    showDecisionRationale ||
    showMoreRejectionReasons ||
    opportunity.rejectionDescription;

  const additionalRejectionsLabel =
    !isExpanded &&
    isBeyondDiscover &&
    !opportunity.isQualified &&
    additionalRejectionReasons.length > 0
      ? "+" + additionalRejectionReasons.length
      : undefined;

  const getInitialText = () => {
    if (isBeyondDiscover && !opportunity.isQualified) {
      if (opportunity.rejectionReasons.length > 0)
        return opportunity.rejectionReasons[0].name;
      else return `Not selected for assessment`;
    } else if (isBeyondAssess && !opportunity.isSelectedForPilot) {
      return `Not selected for pilot project`;
    } else return "";
  };

  const handleViewMoreClick = () => {
    setIsExpanded(!isExpanded);
  };

  const RuledOutItem = (props: {
    text: string;
    chipLabel?: string;
  }): ReactElement => {
    return (
      <RuledOutContainer>
        <ReportGmailerrorred
          style={{
            fontSize: theme.spacing(2),
            color: theme.palette.icon.mediumEmphasis,
          }}
        />
        <Typography variant="body2" color="text.mediumEmphasis">
          {props.text}
        </Typography>
        {props.chipLabel && (
          <Chip
            sx={{ marginLeft: 0.5 }}
            variant="counter"
            color="info"
            size="small"
            label={props.chipLabel}
          />
        )}
      </RuledOutContainer>
    );
  };

  const DescriptionSection = (props: { text: string }): ReactElement => (
    <DescriptionContainer>
      <Typography variant="overline">Description:</Typography>
      <Typography variant="body2" color="text.mediumEmphasis">
        {props.text}
      </Typography>
    </DescriptionContainer>
  );

  return (
    <Stack>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <RuledOutItem
          text={getInitialText()}
          chipLabel={additionalRejectionsLabel}
        />
        {moreContent && (
          <Button onClick={handleViewMoreClick}>
            {isExpanded ? "View Less" : "View More"}
          </Button>
        )}
      </Box>
      <Collapse in={isExpanded}>
        <Stack gap={1}>
          {showDecisionRationale && opportunity.assessmentDecision && (
            <DescriptionSection text={opportunity.assessmentDecision} />
          )}
          {additionalRejectionReasons.map((reason) => (
            <RuledOutItem key={reason.id} text={reason.name} />
          ))}
          {opportunity.rejectionDescription && (
            <DescriptionSection text={opportunity.rejectionDescription} />
          )}
        </Stack>
      </Collapse>
    </Stack>
  );
}
