import { ReactElement, useState } from "react";
import { LeadProject } from "../../../Types/LeadProject";
import { Box, Button, Stack, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import CustomExpendableText from "../../UI/CustomExpendableText";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import RichTextEditor from "../../UI/InputFields/RichTextEditor/RichTextEditor";
import parse from "html-react-parser";

interface LeadProjectBackgroundInformationProps {
  leadProject: LeadProject;
  onRefresh: () => void;
}

export default function LeadProjectBackgroundInformation(
  props: LeadProjectBackgroundInformationProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [isEditing, setIsEditing] = useState(false);
  const [backgroundInformation, setBackgroundInformation] = useState(
    props.leadProject.backgroundInformation
  );

  const handleSave = async () => {
    try {
      await LeadProjectsHttpService.updateLeadProject({
        id: props.leadProject.id,
        backgroundInformation,
      });
      setIsEditing(false);
      props.onRefresh();
    } catch (err) {
      enqueueSnackbar(`Failed to update Background Information`, {
        variant: "error",
      });
      setBackgroundInformation(props.leadProject.backgroundInformation);
    }
  };

  const handleCancel = () => {
    setBackgroundInformation(props.leadProject.backgroundInformation);
    setIsEditing(false);
  };

  return (
    <Stack gap={3}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <Typography variant="h6">Background Information</Typography>
        </Box>
        {!isEditing && (
          <Button variant="contained" onClick={() => setIsEditing(true)}>
            Edit
          </Button>
        )}
      </Box>
      {isEditing ? (
        <Box height={242}>
          <RichTextEditor
            fieldId="Description"
            labelText="Background Information"
            editMode
            fieldValue={backgroundInformation}
            required
            isListsDisabled
            onChange={(e) => setBackgroundInformation(e)}
            data-testid="lead-background-information"
          />
        </Box>
      ) : (
        <Stack gap={0.5}>
          <Typography variant="caption" color="text.mediumEmphasis">
            Description
          </Typography>
          <CustomExpendableText
            text={parse(backgroundInformation)}
            maxLines={6}
          />
        </Stack>
      )}
      {isEditing && (
        <Box display="flex" alignSelf="flex-end" gap={2}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </Box>
      )}
    </Stack>
  );
}
