import { Close, InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Drawer,
  FormControlLabel,
  Stack,
  Switch,
  Toolbar,
  Typography,
  styled,
  Tooltip,
} from "@mui/material";
import { ChangeEvent, ReactElement, useEffect, useState } from "react";
import { FilterSelection, VentureClient } from "../../Types/VentureClient";
import { ProjectFilterCriteria } from "../../Types/Project";
import theme from "../../theme";
import BookmarkToggle from "../UI/InputFields/BookmarkToggle";
import useRoles from "../../Hooks/useRoles";
import CheckboxListWithSearch from "../UI/CheckboxListWithSearch";
import { SelectOption } from "../../Types/Common";
import {
  MY_PROJECTS_TOOLTIP,
  STATUS_FILTER_TOOLTIP,
} from "../../Constants/TooltipText";

interface ProjectKanbanFilterProps {
  open: boolean;
  setOpen: (state: boolean) => void;
  refresh: () => void;
  filterSelection: FilterSelection;
}

const StyledCheckbox = styled(FormControlLabel)(() => ({
  margin: 0,
}));

const CloseButton = styled(Button)(({ theme }) => ({
  color: theme.palette.icon.primary,
}));

export function ProjectKanbanFilter({
  open,
  setOpen,
  refresh,
  filterSelection,
}: ProjectKanbanFilterProps): ReactElement {
  const { isExternalUser, ventureClientId } = useRoles();

  const defaultFilters = JSON.stringify({
    status: ["active"],
    userProjects: !isExternalUser,
    projectOwners: [],
    ventureClients: isExternalUser ? [ventureClientId] : [],
    businessUnits: [],
    programManagers: [],
    focusAreas: [],
  });
  const filterOptions = JSON.parse(
    localStorage.getItem("projectFilters") || defaultFilters
  );

  const [selectOptions, setSelectOptions] =
    useState<FilterSelection>(filterSelection);
  const [activeFilters, setActiveFilters] = useState(filterOptions);
  const [selectedVentureClients, setSelectedVentureClients] = useState<
    VentureClient[]
  >([]);

  useEffect(() => {
    setSelectOptions(filterSelection);
  }, [filterSelection]);

  useEffect(() => {
    const updatedClients = selectOptions.ventureClientSelectOptions.filter(
      (vc) => activeFilters.ventureClients.includes(vc.id)
    );
    setSelectedVentureClients(updatedClients);
  }, [activeFilters.ventureClients, selectOptions.ventureClientSelectOptions]);

  useEffect(() => {
    if (selectedVentureClients) {
      const updatedBusinessUnitsSelectOptions = selectedVentureClients.flatMap(
        (client) => client.businessUnits ?? []
      );

      const updatedProgramManagersSelectOptions =
        selectedVentureClients.flatMap(
          (client) =>
            client.businessUnits?.flatMap((bu) => bu.clientContacts ?? []) ?? []
        );

      const updatedFocusareasSelectOptions = selectedVentureClients.flatMap(
        (client) => client.focusAreas?.map((fu) => fu ?? []) ?? []
      );

      setSelectOptions(() => ({
        ...filterSelection,
        businessUnitsSelectOptions: updatedBusinessUnitsSelectOptions,
        programManagersSelectOptions: updatedProgramManagersSelectOptions,
        focusAreasSelectOptions: updatedFocusareasSelectOptions,
      }));
    }
  }, [selectedVentureClients]);

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const resetFilters = () => {
    setActiveFilters(JSON.parse(defaultFilters));
  };

  const handleAcceptFilterChanges = () => {
    const newFilterCriteria: ProjectFilterCriteria = Object.entries(
      activeFilters
    ).reduce((acc, [key, value]) => {
      if (value !== "" && value !== undefined && value !== null)
        acc = Object.assign({ [key]: value }, acc);
      else acc = Object.assign({ [key]: null }, acc);
      return acc;
    }, {} as ProjectFilterCriteria);

    localStorage.setItem("projectFilters", JSON.stringify(newFilterCriteria));
    refresh();
    setOpen(false);
  };

  const handleFilterByStatus = (
    statusChangeEvent: ChangeEvent<HTMLInputElement>
  ) => {
    const statusOption = statusChangeEvent.target.name;
    const updatedActiveFilters = activeFilters.status || [];
    const filterIndex = updatedActiveFilters.indexOf(statusOption);
    filterIndex === -1
      ? updatedActiveFilters.push(statusOption)
      : updatedActiveFilters.splice(filterIndex, 1);
    setActiveFilters({
      ...activeFilters,
      status: updatedActiveFilters,
    });
  };

  const handleFilterMyProjects = (isOwnerChecked: boolean) => {
    setActiveFilters((prevFilters: any) => {
      return {
        ...prevFilters,
        userProjects: isOwnerChecked,
      };
    });
  };

  const handleProjectOwnersSelect = (users: SelectOption[] | undefined) => {
    const userIds = users?.map((user) => user.id);
    setActiveFilters({
      ...activeFilters,
      projectOwners: userIds,
    });
  };

  const handleVentureClientSelect = (clients: SelectOption[] | undefined) => {
    const selectedClientIds = clients?.map((client) => client.id) || [];

    const filteredVentureClients =
      filterSelection.ventureClientSelectOptions.filter((client) =>
        selectedClientIds.includes(client.id)
      );

    setSelectedVentureClients(filteredVentureClients);

    setActiveFilters({
      ...activeFilters,
      ventureClients: selectedClientIds,
      businessUnits: [],
      programManagers: [],
      focusAreas: [],
    });
  };

  const handleSelectChange = (
    key: string,
    options: SelectOption[] | undefined
  ) => {
    setActiveFilters((prevFilters: any) => ({
      ...prevFilters,
      [key]: options?.map((option) => option.id) || [],
    }));
  };

  const handleOrgUnitSelect = (orgUnits: SelectOption[] | undefined) => {
    handleSelectChange("businessUnits", orgUnits);
  };

  const handleProgramManagersSelect = (
    programManagers: SelectOption[] | undefined
  ) => {
    handleSelectChange("programManagers", programManagers);
  };

  const handleFocusAreasSelect = (focusAreas: SelectOption[] | undefined) => {
    handleSelectChange("focusAreas", focusAreas);
  };

  const handleFilterByBookmark = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setActiveFilters({ ...activeFilters, bookmarked: true });
    } else {
      const updatedFilter = activeFilters;
      delete updatedFilter.bookmarked;
      setActiveFilters({ ...updatedFilter });
    }
  };

  const disableStatusDeselection = activeFilters.status.length < 2;

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={open}
      data-testid="kanban-filter-drawer"
    >
      <Toolbar />
      <Box
        sx={{
          position: "absolute",
          right: 0,
          top: 0,
          padding: theme.spacing(3, 3, 1, 3),
        }}
      >
        <CloseButton
          id="filter-drawer-close-button"
          onClick={handleDrawerClose}
        >
          <Close />
        </CloseButton>
      </Box>
      <Stack
        role="presentation"
        p={theme.spacing(0, 3)}
        gap={4}
        maxWidth="min-content"
      >
        <Box display="flex" alignItems="center">
          <Typography variant="h3">Filters</Typography>
        </Box>
        <Box>
          <Box
            display="flex"
            bgcolor="background.default"
            p={theme.spacing(1, 2)}
            gap={2}
            borderRadius={theme.shape.radius.minimal}
          >
            <StatusCheckbox
              name="Active"
              checked={activeFilters.status?.includes("active")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <StatusCheckbox
              name="On Hold"
              checked={activeFilters.status?.includes("on hold")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <StatusCheckbox
              name="Archived"
              checked={activeFilters.status?.includes("archived")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <StatusCheckbox
              name="Adopted"
              checked={activeFilters.status?.includes("adopted")}
              disableDeselection={disableStatusDeselection}
              handleChange={handleFilterByStatus}
            />
            <BookmarkToggle
              data-testid="filter-checkbox-bookmarked"
              size="medium"
              checked={!!activeFilters.bookmarked}
              handleBookmark={handleFilterByBookmark}
              label="Spotlighted"
            />
          </Box>
        </Box>
        <Box display="flex" alignItems="center" gap={1}>
          <FormControlLabel
            data-testid="my-projects-switch"
            control={
              <Switch
                onChange={(e) => handleFilterMyProjects(e.target.checked)}
                checked={activeFilters.userProjects}
              />
            }
            label={<Typography>My projects</Typography>}
            sx={{ mr: 0 }}
          />
          <Tooltip
            title={MY_PROJECTS_TOOLTIP}
            componentsProps={{
              tooltip: {
                sx: {
                  maxWidth: 308,
                },
              },
            }}
          >
            <InfoOutlined
              sx={{ color: "icon.mediumEmphasis", fontSize: "16px" }}
            />
          </Tooltip>
        </Box>
        <Stack gap={theme.spacing(3)} mt={1}>
          <CheckboxListWithSearch
            onChange={handleProjectOwnersSelect}
            label="Project Owner"
            options={selectOptions.projectOwnersSelectOptions
              ?.filter((po) => po.id)
              .map((po) => ({
                id: po.id as number,
                name: po.name as string,
              }))}
            selectedOptions={
              activeFilters.projectOwners?.map((userId: any) => {
                const matchedUser =
                  selectOptions.projectOwnersSelectOptions?.find(
                    (owner: any) => owner.id === userId
                  );
                return {
                  id: userId,
                  name: matchedUser ? matchedUser.name : "",
                };
              }) || []
            }
          />

          {!isExternalUser && (
            <CheckboxListWithSearch
              onChange={handleVentureClientSelect}
              label="Venture Client"
              options={selectOptions.ventureClientSelectOptions.map((bu) => ({
                id: bu.id as number,
                name: bu.name as string,
              }))}
              selectedOptions={
                activeFilters.ventureClients?.map((vc: any) => {
                  const matchedVc =
                    selectOptions.ventureClientSelectOptions?.find(
                      (client: any) => client.id === vc
                    );
                  return {
                    id: vc,
                    name: matchedVc ? matchedVc.name : "",
                  };
                }) || []
              }
            />
          )}

          <CheckboxListWithSearch
            onChange={handleOrgUnitSelect}
            label="Organizational Unit"
            options={
              selectedVentureClients?.flatMap((client) =>
                client.businessUnits.map((bu) => {
                  const isSingleClient = selectedVentureClients.length === 1;
                  return {
                    id: bu.id as number,
                    name: isSingleClient
                      ? bu.name
                      : `${bu.name} (${client.name})`,
                  };
                })
              ) || []
            }
            selectedOptions={
              activeFilters.businessUnits?.map((bu: any) => {
                const matchedBu =
                  selectOptions.businessUnitsSelectOptions?.find(
                    (unit) => unit.id === bu
                  );
                return {
                  id: bu,
                  name: matchedBu ? matchedBu.name : "",
                };
              }) || []
            }
            disabled={
              !activeFilters.ventureClients ||
              activeFilters.ventureClients.length < 1
            }
          />

          <CheckboxListWithSearch
            onChange={handleProgramManagersSelect}
            label="Program Manager"
            options={
              selectedVentureClients
                ?.flatMap(
                  (client) =>
                    client.businessUnits?.flatMap(
                      (bu) =>
                        bu.clientContacts?.map((contact) => ({
                          ...contact,
                          clientName: client.name, // Add client name to contact
                        })) || []
                    ) || []
                )
                .map((contact) => ({
                  id: contact.id as number,
                  name: `${contact.name} (${contact.clientName})`, // Append client name to contact name
                })) || []
            }
            selectedOptions={
              activeFilters.programManagers?.map((pm: any) => {
                const matchedPm =
                  selectOptions.programManagersSelectOptions?.find(
                    (manager: any) => manager.id === pm
                  );
                return {
                  id: pm,
                  name: matchedPm ? matchedPm.name : "",
                };
              }) || []
            }
            disabled={
              !activeFilters.ventureClients ||
              activeFilters.ventureClients.length < 1
            }
          />
          <CheckboxListWithSearch
            onChange={handleFocusAreasSelect}
            label="Focus Area"
            options={
              selectedVentureClients?.flatMap(
                (client) =>
                  client.focusAreas.map((fa) => ({
                    id: fa.id as number,
                    name: `${fa.name} (${client.name})`,
                  })) || []
              ) || []
            }
            selectedOptions={
              activeFilters.focusAreas?.map((fa: any) => {
                const matchedarea = selectOptions.focusAreasSelectOptions?.find(
                  (area: any) => area.id === fa
                );
                return {
                  id: fa,
                  name: matchedarea ? matchedarea.name : "",
                };
              }) || []
            }
            disabled={
              !activeFilters.ventureClients ||
              activeFilters.ventureClients.length < 1
            }
          />

          <Box display="flex" p={1} alignSelf="flex-end" height="fit-content">
            <Button onClick={resetFilters} variant="text">
              <Close sx={{ fontSize: "1.2rem", mr: 1 }} />
              <Typography variant="button">Reset</Typography>
            </Button>
          </Box>
        </Stack>
      </Stack>
      <Box
        display="flex"
        sx={{
          position: "absolute",
          padding: theme.spacing(3),
          bottom: "0",
          width: "100%",
        }}
      >
        <Button
          fullWidth
          variant="contained"
          onClick={handleAcceptFilterChanges}
        >
          Apply
        </Button>
      </Box>
    </Drawer>
  );
}

interface StatusCheckboxProps {
  name: string;
  checked: boolean;
  disableDeselection: boolean;
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

const StatusCheckbox = ({
  name,
  checked,
  disableDeselection,
  handleChange,
}: StatusCheckboxProps) => {
  const formattedName = name.replace(" ", "-").toLocaleLowerCase();
  const disabled = disableDeselection && checked;

  return (
    <Tooltip title={disabled ? STATUS_FILTER_TOOLTIP : null}>
      <StyledCheckbox
        disabled={disabled}
        slotProps={{
          typography: {
            noWrap: true,
          },
        }}
        control={
          <Checkbox
            name={name.toLocaleLowerCase()}
            data-testid={`filter-checkbox-${formattedName}`}
            edge="start"
            disableRipple
            checked={checked}
            onChange={handleChange}
          />
        }
        label={name}
      />
    </Tooltip>
  );
};
