import {
  Logout,
  RocketLaunchOutlined,
  AssessmentOutlined,
  CorporateFare,
  DashboardOutlined,
} from "@mui/icons-material";
import ListIcon from "@mui/icons-material/List";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { ReactElement } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import theme from "../../theme";
import useRoles from "../../Hooks/useRoles";

interface NavOption {
  title: string;
  to: string;
  iconName: string;
  hidden?: boolean;
}

const iconComponents: { [name: string]: ReactElement } = {
  AssessmentOutlined: (
    <AssessmentOutlined sx={{ transform: "rotate(180deg)" }} />
  ),
  ListIcon: <ListIcon />,
  RocketLaunchOutlined: <RocketLaunchOutlined />,
  CorporateFare: <CorporateFare />,
  PersonOutlineIcon: <PersonOutlineIcon />,
  DashboardOutlined: <DashboardOutlined />,
};

const StyledDrawer = styled(Drawer)(() => ({
  width: theme.spacing(9),
  "& .MuiPaper-root": {
    backgroundColor: theme.palette.surface.primary.invert,
    border: "none",
    width: theme.spacing(9),
  },
}));

const MainNav = styled(List)(() => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  gap: theme.spacing(7.25),
}));

const LogoutItem = styled(ListItem)(() => ({
  cursor: "pointer",
  position: "absolute",
  bottom: theme.spacing(3),
  left: theme.spacing(3),
  width: "fit-content",
  padding: 0,
}));

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: 0,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: theme.spacing(0.5),
  color: theme.palette.icon.primaryInvert.main,
  padding: 0,
}));

const ActiveIcon = styled("div")(() => ({
  width: "6px",
  height: "6px",
  borderRadius: theme.shape.radius.full,
  backgroundColor: theme.palette.icon.brand.main,
}));

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.action.main,
    boxShadow: theme.boxShadows[12],
    ...theme.typography.caption,
  },
}));

interface SideNavProps {
  handleSignOut: () => void;
}

export default function SideNav(props: SideNavProps): ReactElement {
  const location = useLocation();
  const { isExternalUser, ventureClientId } = useRoles();

  const navigationOptions: NavOption[] = [
    {
      title: "Dashboard",
      to: "/dashboard",
      iconName: "DashboardOutlined",
      hidden: !isExternalUser,
    },
    {
      title: "Kanban",
      to: "/projects",
      iconName: "AssessmentOutlined",
    },
    {
      title: "Data & Reports",
      to: "/data",
      iconName: "ListIcon",
    },
    {
      title: "Startups",
      to: "/startups",
      iconName: "RocketLaunchOutlined",
    },
    {
      title: "Clients",
      to: "/venture-clients",
      iconName: "CorporateFare",
      hidden: isExternalUser,
    },
    {
      title: "Organisation",
      to: `/venture-clients/${ventureClientId}`,
      iconName: "CorporateFare",
      hidden: !isExternalUser,
    },
    {
      title: "Contacts",
      to: "/contacts",
      iconName: "PersonOutlineIcon",
    },
  ];

  const pathMapper = (pathname: string) => {
    if (pathname.startsWith("/lead-project")) {
      return "/projects";
    }
    return pathname;
  };

  const isActive = (pathname: string, option: NavOption): boolean => {
    const mappedPath = pathMapper(pathname);
    return option.to === "/"
      ? mappedPath === "/"
      : mappedPath.startsWith(option.to);
  };

  return (
    <StyledDrawer variant="permanent">
      <MainNav>
        {navigationOptions
          .filter((option) => !option.hidden)
          .map((option) => {
            return (
              <StyledTooltip
                key={option.title}
                title={option.title}
                placement="right"
              >
                <NavLink
                  to={option.to}
                  exact
                  data-testid={"navlink-" + option.to.slice(1)}
                >
                  <StyledListItemIcon sx={{ height: "34px" }}>
                    {iconComponents[option.iconName]}
                    {isActive(location.pathname, option) && <ActiveIcon />}
                  </StyledListItemIcon>
                </NavLink>
              </StyledTooltip>
            );
          })}
        <StyledTooltip title="Logout" placement="right-start">
          <LogoutItem onClick={props.handleSignOut}>
            <StyledListItemIcon>
              <Logout />
            </StyledListItemIcon>
          </LogoutItem>
        </StyledTooltip>
      </MainNav>
    </StyledDrawer>
  );
}
