import {
  Box,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import {
  ReactElement,
  Reducer,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { FileHttpService } from "../../../../../Http/File/File.http.service";
import { Project } from "../../../../../Types/Project";
import {
  DisabledFiles,
  File as DocumentFile,
  FilesBelongToProjectAndOpportunity,
  FilesClassifiedByProjectAndOpportunityId,
} from "../../../../../Types/File";
import theme from "../../../../../theme";
import { FilesListSection } from "./FilesListSection";
import { OpportunityFilesSection } from "./OpportunityFilesSection";
import { Opportunity } from "../../../../../Types/Opportunity";
import AddIcon from "@mui/icons-material/Add";
import { GenerateFileModal } from "./GenerateFileModal";
import { FileUploadSection } from "./UploadFileModal";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import { useSnackbar } from "notistack";
import { GlobalProjectEditContext } from "../../../../../Context/ProjectDetailsContext";
import { otherFileTypes } from "../../../../../Constants/Files";
import MapFile from "../../../../../Http/File/File.mapper";

interface ProjectFileSectionProps {
  project: Project;
  projectFileTypes?: string[];
  opportunityFileTypes?: string[];
  disabledFileTypes?: DisabledFiles;
  handleSave: () => void;
  stage: string;
}

export function ProjectFileSection(
  props: ProjectFileSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [lastUploadFile, setLastUploadFile] = useState<number>(0);
  const [editingFileId, setEditingFileId] = useState<number>();
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [opportunitiesWithFiles, setOpportunitiesWithFiles] = useState<
    Opportunity[]
  >([]);
  const [stageSpecificOpportunities, setStageSpecificOpportunities] = useState<
    Opportunity[]
  >([]);
  const [localFiles, setLocalFiles] = useReducer<
    Reducer<
      {
        [key: string | number]: DocumentFile[] | undefined;
      },
      Partial<{
        [key: string | number]: DocumentFile[] | undefined;
      }>
    >
  >((state, newState) => ({ ...state, ...newState }), {});
  const { setGlobalEditMode, globalEditMode } = useContext(
    GlobalProjectEditContext
  );

  const [isFileRefreshing, setIsFileRefreshing] = useState(false);

  const filterFiles = (
    isFileRefreshed = false,
    allFiles: FilesBelongToProjectAndOpportunity = {
      projectFiles: [],
      opportunityFiles: [],
    }
  ) => {
    const files: FilesClassifiedByProjectAndOpportunityId = {};
    const filteredOpportunitiesWithFiles: Opportunity[] = [];
    const projectFiles = isFileRefreshed
      ? allFiles.projectFiles
      : props.project.files;

    files["project"] = filterProjectFiles(projectFiles);

    stageSpecificOpportunities?.forEach((opportunity) => {
      const opportunityFiles = isFileRefreshed
        ? allFiles.opportunityFiles.filter(
            (file) => file.linkableId === opportunity.id
          )
        : opportunity.files;

      const filteredFiles = filterOpportunityFiles(opportunityFiles);
      if (filteredFiles && filteredFiles.length) {
        files[opportunity.id] = filteredFiles;
        filteredOpportunitiesWithFiles.push(opportunity);
      }
    });

    setOpportunitiesWithFiles(filteredOpportunitiesWithFiles);
    setLocalFiles(files);
  };

  useEffect(() => {
    filterFiles();
  }, [props.project.files, stageSpecificOpportunities, props.stage]);

  useEffect(() => {
    setGlobalEditMode(!!editingFileId);
  }, [editingFileId]);

  useEffect(() => {
    const filteredOpportunities: Opportunity[] =
      props.project.opportunities?.filter((opportunity) => {
        if (props.stage === "discover") {
          return true;
        } else if (props.stage === "assess") {
          return !!opportunity.isQualified;
        } else {
          return !!opportunity.isSelectedForPilot;
        }
      }) || [];
    setStageSpecificOpportunities(filteredOpportunities);
  }, [props.project.files, props.stage]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!editingFileId) {
      interval = setInterval(() => {
        refreshFiles();
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [editingFileId, stageSpecificOpportunities, props.stage]);

  const filterProjectFiles = (files: DocumentFile[]): DocumentFile[] => {
    const projectFiles = files?.filter(
      (file) =>
        props.stage === file.type || props.projectFileTypes?.includes(file.type)
    );
    return MapFile.map(projectFiles);
  };

  const filterOpportunityFiles = (files: DocumentFile[]): DocumentFile[] => {
    if (props.opportunityFileTypes) {
      return files.filter(
        (file) =>
          props.opportunityFileTypes?.includes(file.type) ||
          props.stage === file.type
      );
    } else return [];
  };

  const saveChanges = async (): Promise<void> => {
    setEditingFileId(undefined);
    props.handleSave();
  };

  const deleteFile = async (fileId: number) => {
    await FileHttpService.delete(fileId);
    props.handleSave();
  };

  const handleFileRename = (
    id: number,
    name: string,
    objectKey: string | number
  ) => {
    const files = JSON.parse(JSON.stringify(localFiles));
    const changedFileSet = files[objectKey];
    if (changedFileSet) {
      const changedFile = changedFileSet.find(
        (file: DocumentFile) => file.id === id
      );
      changedFile.name = name;
      setLocalFiles({ [objectKey]: changedFileSet });
    }
  };

  const refreshFiles = async () => {
    setIsFileRefreshing(true);
    try {
      const allFiles = await FileHttpService.getFilesByProjectId(
        props.project.id
      );
      allFiles.opportunityFiles = MapFile.map(allFiles.opportunityFiles);
      allFiles.projectFiles = MapFile.map(allFiles.projectFiles);
      filterFiles(true, allFiles);
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(
        `Something went wrong with refreshing files: ${error.response.data.message}`,
        {
          variant: "error",
        }
      );
    } finally {
      setIsFileRefreshing(false);
    }
  };

  const generateDocumentAllowed: boolean =
    props.stage === "assess" || props.stage === "discover";

  const displayfiles: DocumentFile[] = localFiles["project"]
    ? localFiles["project"].filter(
        (file: DocumentFile) => !otherFileTypes.includes(file.type)
      )
    : [];
  const otherFiles: DocumentFile[] = localFiles["project"]
    ? localFiles["project"].filter((file: DocumentFile) =>
        otherFileTypes.includes(file.type)
      )
    : [];

  return (
    <Paper sx={{ padding: theme.spacing(4) }}>
      <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
        <Box justifyContent="space-between" flexDirection="row" display="flex">
          <Typography variant="h6">Files</Typography>
          <Box display="flex" gap={theme.spacing(2)}>
            {!editingFileId && generateDocumentAllowed && (
              <>
                {isFileRefreshing ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  <Tooltip title="Refresh Files">
                    <RefreshOutlinedIcon
                      color="secondary"
                      fontSize="small"
                      cursor="pointer"
                      data-testid="refresh-files-button"
                      onClick={refreshFiles}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Generate a new document">
                  <AddIcon
                    color="secondary"
                    fontSize="small"
                    cursor="pointer"
                    onClick={() => setOpenGenerateModal(true)}
                    data-testid="open-generate-modal-button"
                  />
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
        {displayfiles.length > 0 && (
          <FilesListSection
            files={displayfiles}
            mapId={"project"}
            deleteFile={deleteFile}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            handleFileRename={handleFileRename}
            projectId={props.project.id}
            handleSave={saveChanges}
          />
        )}
        {localFiles && (
          <OpportunityFilesSection
            localFiles={localFiles}
            deleteFile={deleteFile}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            handleFileRename={handleFileRename}
            opportunities={opportunitiesWithFiles}
            lastUploadFileId={lastUploadFile}
            projectStage={props.stage}
            handleSave={saveChanges}
          />
        )}
        {otherFiles.length > 0 && (
          <FilesListSection
            handleSave={saveChanges}
            files={otherFiles}
            mapId={"project"}
            deleteFile={deleteFile}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            handleFileRename={handleFileRename}
          />
        )}

        {!globalEditMode && (
          <FileUploadSection
            project={props.project}
            setLastUploadFile={setLastUploadFile}
            opportunityFileTypes={props.opportunityFileTypes || []}
            projectFileTypes={props.projectFileTypes || []}
            disabledFileTypes={props.disabledFileTypes}
            stageSpecificOpportunities={stageSpecificOpportunities}
            handleSave={saveChanges}
            projectStage={props.stage}
          />
        )}
        {openGenerateModal && (
          <GenerateFileModal
            open={openGenerateModal}
            setOpenGenerateModal={setOpenGenerateModal}
            projectId={props.project.id}
            handleSave={props.handleSave}
            stage={props.stage}
            opportunities={stageSpecificOpportunities}
          />
        )}
      </Box>
    </Paper>
  );
}
