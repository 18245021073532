import { Box } from "@mui/material";
import { ReactElement } from "react";
import { Project } from "../../../../Types/Project";
import ProductDemosSection from "./DemosSection/DemosSection";
import AssessmentConclusion from "./AssessmentConclusion/AssessmentConclusion";
import QualifiedList from "./QualifiedList";

interface AssessStageProps {
  projectData: Project;
  handleSave: () => void;
  handleSaveNoScroll: () => void;
  moveProject: () => void;
  handleChangeTab: (index: number, keepFullWidth?: boolean) => void;
  activeTabs: number[];
  handleRefs: (el: HTMLDivElement | null, index: number) => void;
  fullWidthSectionOpen: boolean;
  setFullWidthSectionOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleBlockedNavigation: (callback?: () => void) => boolean;
}

export default function AssessStage(props: AssessStageProps): ReactElement {
  return (
    <Box display="flex" flexDirection="column" gap={3} width="100%">
      {props.projectData && (
        <>
          <div ref={(element) => props.handleRefs(element, 0)}>
            <QualifiedList
              project={props.projectData}
              handleSave={props.handleSaveNoScroll}
              showFullList={props.fullWidthSectionOpen}
              onFullWidthButtonClick={() =>
                props.handleBlockedNavigation(() =>
                  props.handleChangeTab(0, true)
                )
              }
              expanded={props.activeTabs.includes(0)}
              onChange={() => props.handleChangeTab(0)}
            ></QualifiedList>
          </div>
          <div ref={(element) => props.handleRefs(element, 1)}>
            <ProductDemosSection
              project={props.projectData}
              handleSaveNoScroll={props.handleSaveNoScroll}
              expanded={props.activeTabs.includes(1)}
              onChange={() => props.handleChangeTab(1)}
            />
          </div>
          <div ref={(element) => props.handleRefs(element, 2)}>
            <AssessmentConclusion
              projectId={props.projectData.id}
              opportunities={props.projectData.opportunities}
              assessmentSummary={props.projectData.assessmentSummary}
              handleSave={props.handleSaveNoScroll}
              expanded={props.activeTabs.includes(2)}
              onChange={() => props.handleChangeTab(2)}
            />
          </div>
        </>
      )}
    </Box>
  );
}
