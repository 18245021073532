import {
  Accordion,
  AccordionDetails,
  AccordionProps,
  Box,
  Button,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import {
  Project,
  ProjectProblemInsights,
  RichTextCharLimit,
} from "../../../../Types/Project";
import RichTextEditor from "../../../UI/InputFields/RichTextEditor/RichTextEditor";
import { ReactElement, Reducer, useContext, useReducer, useState } from "react";
import ProjectDetailsAccordionSummary from "../../SharedComponents/ProjectDetailsAccordionSummary";
import { getErrorMessage, isCharLimitExceeded } from "../../../../utils";
import { useSnackbar } from "notistack";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import { GlobalProjectEditContext } from "../../../../Context/ProjectDetailsContext";
import { AiHttpService } from "../../../../Http/Ai/Ai.http.service";
import AutoGenerateTextButton from "../../../UI/AutoGenerateTextButton";
import {
  AUTO_GENERATE_PROBLEM_DESCRIPTION,
  KEY_FINDINGS_PDD,
  KNOWN_SOLUTIONS_TOOLTIP,
  PREVIOUS_PROJECTS_TOOLTIP,
  PROBLEM_DESCRIPTION_TOOLTIP,
  RECOMMENDATION_PDD,
} from "../../../../Constants/TooltipText";
import AutoGenerateBlurBackground from "../../../UI/AutoGenerateBlurBackground";
import TextGenerationRevertModal from "../../../UI/Modals/TextGenerationRevertModal/TextGenerationRevertModal";
import CustomExpendableText from "../../../UI/CustomExpendableText";
import parse from "html-react-parser";
import useSaveProject from "../../../../Hooks/useSaveProject";

const CHAR_LIMIT_KEY_FINDINGS = 350;
const CHAR_LIMIT_RECOMMENDATION = 350;

const charLimitedRichTextFields: RichTextCharLimit[] = [
  {
    id: "deepDiveKeyFindings",
    label: "Key Findings (PDD)",
    charLimit: CHAR_LIMIT_KEY_FINDINGS,
  },
  {
    id: "deepDiveRecommendation",
    label: "Recommendation (PDD)",
    charLimit: CHAR_LIMIT_RECOMMENDATION,
  },
];

interface ProblemInsightsSectionProps extends Omit<AccordionProps, "children"> {
  project: Project;
  handleSave: (withScroll?: boolean | undefined) => void;
}

export default function ProblemInsightsSection(
  props: ProblemInsightsSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();

  const [editMode, setEditMode] = useState(false);
  const [project, setProject] = useReducer<
    Reducer<ProjectProblemInsights, Partial<ProjectProblemInsights>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.project.id,
    description: props.project.description,
    deepDiveKeyFindings: props.project.deepDiveKeyFindings,
    deepDiveRecommendation: props.project.deepDiveRecommendation,
    previousProjects: props.project.previousProjects,
    knownSolutions: props.project.knownSolutions,
  });

  const { globalEditMode, setGlobalEditMode } = useContext(
    GlobalProjectEditContext
  );

  const [isGenerated, setIsGenerated] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isRevertModalOpen, setIsRevertModalOpen] = useState(false);
  const [initialDescription, setInitialDescription] = useState("");

  const generateDescription = async () => {
    setIsGenerating(true);
    setInitialDescription(project.description);
    try {
      const generatedDescription = await AiHttpService.autoGenerateText(
        project.description,
        "problem-description"
      );
      setProject({ description: generatedDescription });
      setIsGenerated(true);
    } catch (error) {
      enqueueSnackbar(`Could not auto generate`, {
        variant: "error",
      });
    } finally {
      setIsGenerating(false);
    }
  };

  const handleRevertModal = (open: boolean, isReverted?: boolean) => {
    setIsRevertModalOpen(open);
    if (isReverted) {
      setIsGenerated(false);
      setProject({ description: initialDescription });
    }
  };

  const handleCancelEdit = () => {
    if (!props.expanded) return;

    setProject({
      description: props.project.description,
      deepDiveKeyFindings: props.project.deepDiveKeyFindings,
      deepDiveRecommendation: props.project.deepDiveRecommendation,
      previousProjects: props.project.previousProjects,
      knownSolutions: props.project.knownSolutions,
    });
    setEditMode(false);
    setGlobalEditMode(false);
    setIsGenerated(false);
  };

  const handleSaveSection = async () => {
    if (!props.expanded) return;

    for (const field of charLimitedRichTextFields) {
      if (
        isCharLimitExceeded(
          (project as Project)[field.id] as string,
          field.charLimit
        )
      ) {
        enqueueSnackbar(`Maximum character limit exceeded for ${field.label}`, {
          variant: "error",
        });
        return;
      }
    }

    await ProjectHttpService.updateProject(project as Project)
      .then(() => {
        props.handleSave(false);
        setEditMode(false);
        setGlobalEditMode(false);
        setIsGenerated(false);
      })
      .catch((error) => {
        const errorMessage = getErrorMessage(error);
        enqueueSnackbar(`could not save the project: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleEditButtonClick = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setEditMode(true);
    setGlobalEditMode(true);
    setIsGenerated(false);
  };

  const EditButton = () => {
    return (
      <Button
        variant="contained"
        onClick={handleEditButtonClick}
        data-testid="edit-problem-insights"
        disabled={globalEditMode}
      >
        Edit
      </Button>
    );
  };

  useSaveProject(handleSaveSection);

  return (
    <Accordion
      expanded={props.expanded}
      data-testid="problem-insights-accordion"
      onChange={props.onChange}
      disabled={editMode}
    >
      <ProjectDetailsAccordionSummary
        actionButton={props.expanded && !editMode ? <EditButton /> : null}
      >
        Problem Insights
      </ProjectDetailsAccordionSummary>
      <AccordionDetails>
        <Stack gap={5}>
          <Box {...(editMode && { height: "320px" })}>
            {editMode ? (
              <RichTextEditor
                fieldId="description"
                labelText="Problem Description"
                editMode={editMode}
                fieldValue={project.description}
                required
                isListsDisabled
                onChange={(value) => setProject({ description: value })}
                toolTipText={PROBLEM_DESCRIPTION_TOOLTIP}
              >
                <>
                  <AutoGenerateTextButton
                    isGenerated={isGenerated}
                    isGenerating={isGenerating}
                    handleRevertModal={handleRevertModal}
                    generateText={generateDescription}
                    toolTipText={AUTO_GENERATE_PROBLEM_DESCRIPTION}
                  />

                  {isGenerating && <AutoGenerateBlurBackground />}
                  {isRevertModalOpen && (
                    <TextGenerationRevertModal
                      isRevertModalOpen={isRevertModalOpen}
                      handleRevertModal={handleRevertModal}
                      setIsGenerated={setIsGenerated}
                    />
                  )}
                </>
              </RichTextEditor>
            ) : (
              <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                <InputLabel>
                  <Typography variant="caption">Problem Description</Typography>
                </InputLabel>
                <CustomExpendableText
                  text={parse(props.project.description || "--")}
                  maxLines={5}
                />
              </Stack>
            )}
          </Box>

          <Box
            display="grid"
            gridTemplateColumns="1fr 1fr"
            gridTemplateRows="auto"
            gap={5}
          >
            <Box {...(editMode && { height: "204px" })}>
              {editMode ? (
                <RichTextEditor
                  fieldId="previousProjects"
                  labelText="Previous Projects"
                  editMode={editMode}
                  fieldValue={project.previousProjects}
                  isListsDisabled
                  onChange={(value) =>
                    setProject({
                      previousProjects: value,
                    })
                  }
                  toolTipText={PREVIOUS_PROJECTS_TOOLTIP}
                />
              ) : (
                <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                  <InputLabel>
                    <Typography variant="caption">Previous Projects</Typography>
                  </InputLabel>
                  <CustomExpendableText
                    text={parse(props.project.previousProjects || "--")}
                    maxLines={5}
                  />
                </Stack>
              )}
            </Box>

            {/* Known Solutions */}
            <Box {...(editMode && { height: "204px" })}>
              {editMode ? (
                <RichTextEditor
                  fieldId="knownSolutions"
                  labelText="Known Solutions"
                  editMode={editMode}
                  fieldValue={project.knownSolutions}
                  isListsDisabled
                  onChange={(value) =>
                    setProject({
                      knownSolutions: value,
                    })
                  }
                  toolTipText={KNOWN_SOLUTIONS_TOOLTIP}
                />
              ) : (
                <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                  <InputLabel>
                    <Typography variant="caption">Known Solutions</Typography>
                  </InputLabel>
                  <CustomExpendableText
                    text={parse(props.project.knownSolutions || "--")}
                    maxLines={5}
                  />
                </Stack>
              )}
            </Box>

            <Box {...(editMode && { height: "204px" })}>
              {editMode ? (
                <RichTextEditor
                  fieldId="deepDiveKeyFindings"
                  labelText="Key Findings (PDD)"
                  isListsDisabled
                  editMode={editMode}
                  fieldValue={project.deepDiveKeyFindings}
                  onChange={(value) =>
                    setProject({
                      deepDiveKeyFindings: value,
                    })
                  }
                  maxCharacter={CHAR_LIMIT_KEY_FINDINGS}
                  toolTipText={KEY_FINDINGS_PDD}
                />
              ) : (
                <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                  <InputLabel>
                    <Typography variant="caption">
                      Key Findings (PDD)
                    </Typography>
                  </InputLabel>
                  <CustomExpendableText
                    text={parse(props.project.deepDiveKeyFindings || "--")}
                    maxLines={5}
                  />
                </Stack>
              )}
            </Box>

            <Box {...(editMode && { height: "204px" })}>
              {editMode ? (
                <RichTextEditor
                  fieldId="deepDiveRecommendation"
                  labelText="Recommendation (PDD)"
                  isListsDisabled
                  editMode={editMode}
                  fieldValue={project.deepDiveRecommendation}
                  onChange={(value) =>
                    setProject({
                      deepDiveRecommendation: value,
                    })
                  }
                  maxCharacter={CHAR_LIMIT_RECOMMENDATION}
                  toolTipText={RECOMMENDATION_PDD}
                />
              ) : (
                <Stack gap={0.5} sx={{ "& p": { m: 0 } }}>
                  <InputLabel>
                    <Typography variant="caption">
                      Recommendation (PDD)
                    </Typography>
                  </InputLabel>
                  <CustomExpendableText
                    text={parse(props.project.deepDiveRecommendation || "--")}
                    maxLines={5}
                  />
                </Stack>
              )}
            </Box>
          </Box>

          {editMode && (
            <Box marginLeft="auto" display="flex" gap={2}>
              <Button onClick={handleCancelEdit}>Cancel</Button>
              <Button variant="contained" onClick={handleSaveSection}>
                Save
              </Button>
            </Box>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
