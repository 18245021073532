import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  useMediaQuery,
  DialogTitle,
  RadioGroup,
  Radio,
  Checkbox,
  Stack,
  Typography,
  styled,
  FormControlLabel,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { ReactElement, useState } from "react";
import { FileHttpService } from "../../../../../Http/File/File.http.service";
import theme from "../../../../../theme";
import { GenerateFile } from "../../../../../Types/File";
import { Opportunity } from "../../../../../Types/Opportunity";
import { LeadOpportunity } from "../../../../../Types/LeadProject";
import { formatDate } from "../../../../../utils";

interface GenerateFileModalProps {
  open: boolean;
  setOpenGenerateModal: (arg: boolean) => void;
  projectId: number;
  handleSave: () => void;
  stage: string;
  opportunities: (Opportunity | LeadOpportunity)[];
}

const OptionContainer = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  background: theme.palette.surface.secondary.main,
  borderRadius: theme.shape.radius.cardSmall,
  gap: theme.spacing(1),
  borderWidth: 1,
  borderStyle: "solid",
  borderColor: "transparent",
}));

const OptionContent = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  paddingRight: theme.spacing(1),
}));

const StyledDialogContent = styled(DialogContent)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(4),
}));

export function GenerateFileModal(props: GenerateFileModalProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedFile, setSelectedFile] = useState<GenerateFile>();
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [selectedOpportunityIds, setSelectedOpportunityIds] = useState<
    number[]
  >([]);

  const selectedStartup = props.opportunities.find(
    (opp) => (opp as Opportunity).isSelectedForPilot
  );
  const haveAllDecisionRationale = props.opportunities
    .filter((opp) => (opp as Opportunity).isQualified)
    .every((opp) => (opp as Opportunity).assessmentDecision);
  const disableAssessmentReport = !selectedStartup || !haveAllDecisionRationale;

  const documentOptions: GenerateFile[] = [
    {
      type: "Qualified List Presentation",
      stage: "assess",
      linkableType: "project",
    },
    {
      type: "Strategic Onepager",
      stage: "lead",
      linkableType: "leadOpportunity",
      showStartups: true,
    },
    {
      type: "Curated List Presentation",
      stage: "discover",
      linkableType: "project",
    },
    { type: "Problem Deep Dive", stage: "discover", linkableType: "project" },
    {
      type: "Briefing Documents",
      stage: "assess",
      linkableType: "opportunity",
      showStartups: true,
      options: [
        "Strategic Onepager",
        "Internal Product Demo Briefing",
        "External Product Demo Briefing",
      ],
    },
    {
      type: "Assessment Report",
      stage: "assess",
      linkableType: "project",
      disabled: disableAssessmentReport,
    },
  ];

  const generateFile = async () => {
    if (selectedFile) {
      const isSingleFileGenerate = !["opportunity", "leadOpportunity"].includes(
        selectedFile.linkableType
      );
      try {
        if (isSingleFileGenerate) {
          await FileHttpService.generateFile(
            props.projectId,
            selectedFile.linkableType,
            selectedFile.type,
            true
          );
        } else {
          await Promise.all(
            selectedOptions.map((option) =>
              selectedOpportunityIds.map((opportunityId) =>
                FileHttpService.generateFile(
                  opportunityId,
                  selectedFile.linkableType,
                  option,
                  true
                )
              )
            )
          );
        }
        props.handleSave();
        props.setOpenGenerateModal(false);
        enqueueSnackbar(
          "Document generation successfully started. It will be available for downloading soon.",
          {
            variant: "success",
          }
        );
      } catch (error: any) {
        enqueueSnackbar(error.message, {
          variant: "error",
        });
      }
    }
  };

  const handleSelectedType = (type: string) => {
    const selectedFileType = documentOptions.find(
      (option) => option.type === type && option.stage === props.stage
    );
    setSelectedFile(selectedFileType);
    setSelectedOptions([]);
    setSelectedOpportunityIds([]);
    if (selectedFileType?.showStartups && !selectedFileType.options)
      setSelectedOptions([selectedFileType.type]);
  };

  const handleSelectOpportunityId = (id: number) => {
    setSelectedOpportunityIds((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((opportunityId) => opportunityId !== id)
        : [...prevSelected, id]
    );
  };

  const handleSelectAllOpportunities = () => {
    if (props.opportunities.length === selectedOpportunityIds.length)
      setSelectedOpportunityIds([]);
    else
      setSelectedOpportunityIds([
        ...props.opportunities.map((opprtunity) => opprtunity.id),
      ]);
  };

  const handleSelectOption = (option: string) => {
    setSelectedOptions((prevSelected) =>
      prevSelected.includes(option)
        ? prevSelected.filter((_option) => option !== _option)
        : [...prevSelected, option]
    );
  };

  const handleSelectAllOptions = () => {
    if (!selectedFile?.options) return;
    if (selectedFile.options.length === selectedOptions.length)
      setSelectedOptions([]);
    else setSelectedOptions(selectedFile.options);
  };

  const shouldOpportunityHaveDemo = (opportunity: Opportunity): boolean => {
    if (selectedFile?.type !== "Briefing Documents") return false;
    return (
      opportunity?.productDemos.length < 1 &&
      (selectedOptions.includes("Internal Product Demo Briefing") ||
        selectedOptions.includes("External Product Demo Briefing"))
    );
  };

  const shouldDisableGenerateButton = (() => {
    if (!selectedFile) return true;

    const missingRequiredFields =
      selectedOpportunityIds.length < 1 || selectedOptions.length < 1;

    const opportunityMissingDemo = props.opportunities.find(
      (opportunity) =>
        selectedOpportunityIds.includes(opportunity.id) &&
        shouldOpportunityHaveDemo(opportunity as Opportunity)
    );

    if (
      (selectedFile.linkableType === "opportunity" ||
        selectedFile.linkableType === "leadOpportunity") &&
      (missingRequiredFields || opportunityMissingDemo)
    )
      return true;
    return false;
  })();

  return (
    <Dialog
      fullScreen={fullScreen}
      open={props.open}
      onClose={() => props.setOpenGenerateModal(false)}
      data-testid="generate-file-modal"
      maxWidth="xs"
      fullWidth
      PaperProps={{
        sx: {
          gap: theme.spacing(4),
        },
      }}
    >
      <DialogTitle>Generate Documents</DialogTitle>
      <StyledDialogContent>
        <RadioGroup
          value={selectedFile?.type || ""}
          onChange={(e) => handleSelectedType(e.target.value as string)}
          sx={{ gap: theme.spacing(2) }}
        >
          {documentOptions
            .filter((document) => document.stage === props.stage)
            .map((option) => (
              <FormControlLabel
                key={option.type}
                value={option.type}
                disabled={option.disabled}
                control={
                  <Radio
                    sx={{
                      padding: theme.spacing(1),
                      marginRight: theme.spacing(1),
                    }}
                  />
                }
                label={
                  <Typography
                    variant="subtitle1"
                    color={option.disabled ? "text.disabled" : "initial"}
                  >
                    {option.type}
                  </Typography>
                }
              />
            ))}
        </RadioGroup>
        {selectedFile?.options && (
          <Stack gap={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">Documents Type</Typography>
              <Button onClick={handleSelectAllOptions}>
                {selectedFile.options.length === selectedOptions.length
                  ? "Deselect All"
                  : "Select All"}
              </Button>
            </Box>
            {selectedFile?.options.map((option) => (
              <OptionContainer key={option}>
                <Checkbox
                  sx={{ padding: theme.spacing(1) }}
                  checked={selectedOptions.includes(option)}
                  onChange={() => handleSelectOption(option)}
                  data-testid={`option-${option}`}
                />
                <Typography>{option}</Typography>
              </OptionContainer>
            ))}
          </Stack>
        )}
        {selectedFile?.showStartups && (
          <Stack gap={1}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Typography variant="subtitle1">Startups</Typography>
              <Button onClick={handleSelectAllOpportunities}>
                {props.opportunities.length === selectedOpportunityIds.length
                  ? "Deselect All"
                  : "Select All"}
              </Button>
            </Box>
            {props.opportunities.map((opportunity) => {
              const showDemoWarning = shouldOpportunityHaveDemo(
                opportunity as Opportunity
              );
              const _opportunity = opportunity as Opportunity;
              const demo = _opportunity?.productDemos?.[0];
              return (
                <Stack key={opportunity.id} gap={0.5}>
                  <OptionContainer
                    sx={{
                      borderColor: showDemoWarning
                        ? theme.palette.surface.alert
                        : "transparent",
                    }}
                  >
                    <Checkbox
                      sx={{ padding: theme.spacing(1) }}
                      checked={selectedOpportunityIds.includes(opportunity.id)}
                      onChange={() => handleSelectOpportunityId(opportunity.id)}
                      data-cy={opportunity.startup.name}
                    />
                    <OptionContent>
                      <Typography>{opportunity.startup.name}</Typography>
                      {demo && demo.date && (
                        <Typography
                          variant="overline"
                          color="text.mediumEmphasis"
                        >
                          Demo {formatDate(new Date(demo.date))}
                        </Typography>
                      )}
                    </OptionContent>
                  </OptionContainer>
                  {showDemoWarning && (
                    <Typography variant="caption" color="text.alert">
                      Please add a demo for this startup to generate briefing
                      documents
                    </Typography>
                  )}
                </Stack>
              );
            })}
          </Stack>
        )}
      </StyledDialogContent>
      <DialogActions>
        <Button
          autoFocus
          onClick={() => props.setOpenGenerateModal(false)}
          color="primary"
          id="cancel-modal-button"
        >
          Cancel
        </Button>
        <Button
          autoFocus
          onClick={() => generateFile()}
          variant="contained"
          id="generate-modal-button"
          disabled={shouldDisableGenerateButton}
        >
          Generate
        </Button>
      </DialogActions>
    </Dialog>
  );
}
