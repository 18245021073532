import { ReactElement, useContext } from "react";
import { Opportunity } from "../../../../../../Types/Opportunity";
import { Box, IconButton, styled } from "@mui/material";
import { GlobalLoaderContext } from "../../../../../../Context/LoaderContext";
import { DomainVerification } from "@mui/icons-material";
import theme from "../../../../../../theme";

const StyledIconButton = styled(IconButton)(() => ({
  width: "fit-content",
  alignSelf: "center",
}));

interface RecommendStartupProps {
  opportunity: Opportunity;
  onChangeRecommended: (recommended: boolean) => void;
  isAddingStartup: boolean;
}

export default function RecommendStartup(
  props: RecommendStartupProps
): ReactElement {
  const { globalLoader } = useContext(GlobalLoaderContext);

  const handleRecommendStartup = (event: React.MouseEvent<HTMLElement>) => {
    if (!globalLoader && !props.isAddingStartup) {
      event.stopPropagation();
      props.onChangeRecommended(!props.opportunity.recommendedForDemo);
    }
  };

  return (
    <Box display="flex" justifyContent="center">
      <StyledIconButton
        onClick={handleRecommendStartup}
        data-testid={`${
          props.opportunity.recommendedForDemo ? "" : "not-"
        }recommended-startup-demo-${props.opportunity.id}`}
      >
        <DomainVerification
          fontSize="small"
          sx={{
            color: props.opportunity.recommendedForDemo
              ? theme.palette.icon.success
              : theme.palette.icon.disabled,
          }}
        />
      </StyledIconButton>
    </Box>
  );
}
