import { Box, styled, Tab, Tabs, Tooltip, Typography } from "@mui/material";
import { ReactElement, useContext, useEffect, useRef, useState } from "react";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";
import theme from "../../../../theme";
import {
  StartupCounts,
  StartupSecondaryTabItems,
} from "../../../../Types/Startup";
import { thousandSeparator } from "../../../../utils";

const StyledBox = styled(Box)({
  transition: "all 0.3s",
  width: "100%",
  "&.stickied": {
    width: `calc(100vw - ${theme.spacing(9)})`,
    marginLeft: `calc((100vw - 100% - ${theme.spacing(9)})/-2)`,
    boxShadow: theme.boxShadows[3],
  },
  backgroundColor: theme.palette.surface.primary.main,
  display: "flex",
  justifyContent: "center",
});

const StyledTabs = styled(Tabs)({
  "& .MuiTabs-flexContainer": {
    borderBottom: "none",
  },
  "& .MuiTab-root": {
    padding: theme.spacing(2, 3),
    ...theme.typography.subtitle2,
    "&.Mui-selected": {
      color: theme.palette.text.action.main,
    },
  },
  "& .MuiTabs-indicator": {
    backgroundColor: theme.palette.surface.action.main,
  },
});

interface Props {
  handleChangeTab: (index: number) => void;
  tabs?: StartupSecondaryTabItems[];
  activeTab: number | null;
  headerHeight: number;
  counts?: StartupCounts;
}

function SecondaryTab(props: Props): ReactElement {
  const [isStickied, setIsStickied] = useState(false);
  const { globalLoader } = useContext(GlobalLoaderContext);

  const countsMapping: {
    All: number;
    Leads: number;
    Curated: number;
    Qualified: number;
    Highlighted: number;
    "Selected for Pilot": number;
    Adopted: number;
  } = {
    All: props.counts?.allStartupsCount || 0,
    Leads: props.counts?.leadStartupsCount || 0,
    Highlighted: props.counts?.leadStartupsCount || 0,
    Curated: props.counts?.curatedStartupsCount || 0,
    Qualified: props.counts?.qualifiedStartupsCount || 0,
    "Selected for Pilot": props.counts?.selectedStartupsCount || 0,
    Adopted: props.counts?.adoptedStartupsCount || 0,
  };

  const navigationRef = useRef<Element>(null);

  const navigationHeight = navigationRef.current
    ? navigationRef.current.getBoundingClientRect().height
    : 0;

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        if (e.intersectionRatio < 1) {
          setIsStickied(
            e.boundingClientRect.bottom <=
              props.headerHeight + navigationHeight + 1
          );
        }
      },
      {
        root: null,
        threshold: 0,
        rootMargin: `-${
          props.headerHeight + navigationHeight + 1
        }px 0px 0px 0px`,
      }
    );

    navigationRef.current && observer.observe(navigationRef.current);

    return () => {
      navigationRef.current && observer.unobserve(navigationRef.current);
    };
  }, [navigationRef, navigationHeight, props.headerHeight]);

  return (
    <StyledBox className={isStickied ? "stickied" : ""} ref={navigationRef}>
      <StyledTabs value={props.activeTab}>
        {props.tabs?.map((tab, index) => {
          const count = countsMapping[tab.title as keyof typeof countsMapping];
          const isActive = props.activeTab === index;
          return (
            <Tooltip title={tab.tooltipText} key={index}>
              <Tab
                data-testid={"secondary-tab-type-" + index}
                label={
                  <Box display="flex" gap={1}>
                    <Typography variant="subtitle2">{tab.title}</Typography>
                    <Typography
                      color={isActive ? "inherit" : "text.mediumEmphasis"}
                    >
                      {globalLoader ? <>...</> : thousandSeparator(count)}
                    </Typography>
                  </Box>
                }
                onClick={() => {
                  props.handleChangeTab(index);
                }}
              />
            </Tooltip>
          );
        })}
      </StyledTabs>
    </StyledBox>
  );
}

export default SecondaryTab;
