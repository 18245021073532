import React, { Dispatch, ReactElement, SetStateAction, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { Auth } from "aws-amplify";
import { useSnackbar } from "notistack";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import theme from "../../theme";
import AuthenticationCard from "./AuthenticationCard";
import { getClientSSO, getErrorMessage } from "../../utils";
import useClient from "../../Hooks/useClient";

interface Props {
  setSignedIn: Dispatch<SetStateAction<boolean>>;
  setFormType: (x: string) => void;
  // eslint-disable-next-line
  setUser: (x: any) => void;
}

const ImageContainer = styled(Box)<{ src: string; alt: string }>(() => ({
  height: theme.spacing(8),
  maxWidth: "65%",
  alignSelf: "center",
  objectFit: "contain",
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2.5),
}));

const FooterRow = styled(Box)(() => ({
  position: "absolute",
  bottom: 24,
  left: 24,
  gap: theme.spacing(6),
  display: "flex",
}));

export default function SignIn(props: Props): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { loginPageLogo } = useClient();
  const clientSSO = getClientSSO();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const textFieldForPasswordRef = React.useRef<HTMLInputElement>(null);

  const handleSignIn = async () => {
    try {
      const user = await Auth.signIn(email, password);
      const sessionStartTime = new Date().getTime().toString();
      window.localStorage.setItem("sessionStartTime", sessionStartTime);

      if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
        props.setUser(user);
        handleFormChange("activateAccount");
      } else {
        props.setSignedIn(true);
      }
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    }
  };

  const handleSignInSSO = async () => {
    if (!clientSSO) return;
    try {
      await Auth.federatedSignIn({ customProvider: clientSSO.customProvider });
      // eslint-disable-next-line
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      enqueueSnackbar(errorMessage, {
        variant: "error",
      });
    }
  };

  const handleFormChange = (type: string) => {
    props.setFormType(type);
  };

  return (
    <>
      <AuthenticationCard>
        <ImageContainer
          component="img"
          alt="logo"
          src={loginPageLogo}
          draggable="false"
        />
        <Typography variant="subtitle2">Sign In</Typography>
        <Stack gap={theme.spacing(3)}>
          <TextField
            id="outlined-required"
            label="Username"
            autoComplete="off"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            onKeyDown={(event) => {
              if (event.key === "Enter" && textFieldForPasswordRef.current) {
                textFieldForPasswordRef.current.focus();
              }
            }}
          />
          <Stack gap={theme.spacing(1)}>
            <TextField
              id="outlined-disabled"
              label="Password"
              autoComplete="new-password"
              value={password}
              type={showPassword ? "text" : "password"}
              inputRef={textFieldForPasswordRef}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  handleSignIn();
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      data-testid="password-visibility-toggle"
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      sx={{ color: theme.palette.icon.mediumEmphasis }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="text"
              onClick={() => handleFormChange("resetPasswordToken")}
              sx={{ alignSelf: "end" }}
            >
              Forgot Password?
            </Button>
          </Stack>
        </Stack>
        <Button
          type="submit"
          onClick={handleSignIn}
          variant="contained"
          id="save-project-button"
        >
          Sign In
        </Button>
        {clientSSO && (
          <>
            <Typography alignSelf="center" color="text.mediumEmphasis">
              or
            </Typography>
            <Button
              type="submit"
              variant="contained"
              color="secondary"
              onClick={handleSignInSSO}
            >
              Company Single Sign-on (SSO)
            </Button>
          </>
        )}
      </AuthenticationCard>
      <FooterRow>
        <Typography color="text.primaryInvert.main" variant="caption">
          © 2024 27pilots Deloitte GmbH - All rights Reserved
        </Typography>
        <Link
          target="_blank"
          href="https://www.27pilots.com/legal/"
          variant="button"
          color="text.action.secondary"
        >
          Imprint
        </Link>
      </FooterRow>
    </>
  );
}
