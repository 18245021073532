import { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Paper,
  Typography,
  styled,
  Chip,
  Stack,
} from "@mui/material";
import theme from "../../../theme";
import { Startup } from "../../../Types/Startup";
import { StartupHttpService } from "../../../Http/Startup/Startup.http.service";
import { useSnackbar } from "notistack";
import { getErrorMessage } from "../../../utils";
import useRoles from "../../../Hooks/useRoles";
import ContactName from "../../Contacts/ContactCard/ContactName";
import ContactCardContacts from "../../Contacts/ContactCard/ContactCardContacts";
import StartupTeamModal from "./StartupTeamModal";
import { Add } from "@mui/icons-material";
import CreateStartupContactModal from "../../Contacts/StartupComponents/CreateStartupContactModal";
import { StartupContactDTO } from "../../../Types/StartupContact";

const StartupTeamWrapper = styled(Paper)(() => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(3),
  ariaLabel: "main-contact-name",
  position: "relative",
  padding: theme.spacing(3),
  backgroundColor: theme.palette.surface.primary.main,
}));

const sortContacts = (a: StartupContactDTO, b: StartupContactDTO) => {
  if (!a.founder === !b.founder) return a.name < b.name ? -1 : 1;

  return a.founder ? -1 : 1;
};

interface StartupTeamProps {
  startupId: number;
}

export default function StartupTeam({
  startupId,
}: StartupTeamProps): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser } = useRoles();

  const [startup, setStartup] = useState<Partial<Startup>>();
  const [modalOpen, setModalOpen] = useState(false);
  const [addContactModalOpen, setAddContactModalOpen] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const sortedContacts = startup?.additionalContacts?.sort(sortContacts) ?? [];

  const contacts = isExternalUser
    ? [...(sortedContacts.length ? [sortedContacts[0]] : [])]
    : sortedContacts ?? [];

  const handleViewAll = () => {
    setModalOpen(true);
  };

  const handleSave = () => {
    setRefresh((prevState) => !prevState);
  };

  useEffect(() => {
    StartupHttpService.getStartupById(startupId)
      .then((startup) => {
        setStartup({
          id: startup.id,
          additionalContacts: startup.additionalContacts,
          mainContact: startup.mainContact,
        });
      })
      .catch((error: any) => {
        const message = getErrorMessage(error);
        enqueueSnackbar(message, { variant: "error" });
      });
  }, [refresh]);

  return (
    <>
      <StartupTeamWrapper data-testid="main-contact-name">
        <Box display="flex" gap={1} alignItems="center">
          <Typography variant="h6">
            {isExternalUser ? "Main Contact" : "Startup Team"}
          </Typography>
          {!isExternalUser && (
            <Chip label={contacts.length} variant="counter" color="info" />
          )}
        </Box>
        {contacts.length === 0 ? (
          <Typography
            color={theme.palette.text.disabled}
            data-testid="no-contact-added-text"
          >
            No Contacts added
          </Typography>
        ) : (
          <>
            <ContactName
              name={contacts[0].name}
              title={contacts[0].title}
              orientation="horizontal"
            />
            <ContactCardContacts contact={contacts[0]} />
          </>
        )}
        {!isExternalUser && (
          <Stack gap={1}>
            <Button
              variant="contained"
              startIcon={<Add />}
              onClick={() => setAddContactModalOpen(true)}
            >
              Add Contact
            </Button>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={handleViewAll}
            >
              View All Contacts
            </Button>
          </Stack>
        )}
      </StartupTeamWrapper>
      {modalOpen && (
        <StartupTeamModal
          contacts={contacts}
          startupId={startupId}
          handleSave={handleSave}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
        />
      )}
      {addContactModalOpen && (
        <CreateStartupContactModal
          handleSave={handleSave}
          modalOpen={addContactModalOpen}
          setModalOpen={setAddContactModalOpen}
          startupId={startupId}
        />
      )}
    </>
  );
}
