import { RocketLaunchOutlined, FilterList } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  InputAdornment,
  Pagination,
  TextField,
  Typography,
  debounce,
  IconButton,
  Divider,
  styled,
  Stack,
  Skeleton,
} from "@mui/material";
import {
  Fragment,
  ReactElement,
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { GlobalLoaderContext } from "../../Context/LoaderContext";
import { StartupHttpService } from "../../Http/Startup/Startup.http.service";
import {
  Startup,
  StartupCounts,
  StartupFilterCriteria,
} from "../../Types/Startup";
import CreateStartupModal from "./CreateStartupModal";
import { FilterDrawer } from "./FilterDrawer";
import { unstable_batchedUpdates } from "react-dom";
import PitchbookSearchModal from "../UI/Modals/PitchbookSearchModal/PitchbookSearchModal";
import useRoles from "../../Hooks/useRoles";
import StartupCard from "./StartupCard";
import theme from "../../theme";
import PageHeader from "../UI/PageHeader";
import PrimaryTab from "./DetailsPage/StartupTabs/PrimaryTab";
import SecondaryTab from "./DetailsPage/StartupTabs/SecondaryTab";
import { enqueueSnackbar } from "notistack";
import { getErrorMessage } from "../../utils";
import ContentWrapper from "../ProjectDetails/ContentWrapper";
import { startupTabs } from "../../Constants/Startup";

const PRIMARY_HEADER_OFFSET = 34;
const SECONDARY_HEADER_OFFSET = 8;

const StyledHeader = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  marginBottom: theme.spacing(2),
  gap: theme.spacing(4),
  zIndex: 3,
  position: "relative",
}));

const StyledSearchBar = styled(TextField)(() => ({
  width: "40%",
  marginRight: "auto",
  backgroundColor: theme.palette.surface.primary.main,
  boxShadow: theme.boxShadows[24],
  height: theme.spacing(6),
  padding: `10px 16px`,
  borderRadius: theme.shape.radius.full,
}));

const AddStartup = styled(Button)(({ theme }) => ({
  padding: `${theme.spacing(1.3)} ${theme.spacing(2.75)}`,
}));

const StyledEndAdornment = styled(InputAdornment)(() => ({
  gap: theme.spacing(2),
}));

const SearchTypeButton = styled(Button, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $selected }: { $selected: boolean }) => ({
  borderRadius: "32px",
  background: $selected
    ? theme.palette.surface.action.secondary
    : "transparent",
  color: $selected
    ? theme.palette.text.action.main
    : theme.palette.text.mediumEmphasis,
  "&:hover": {
    background: $selected
      ? theme.palette.surface.disabled
      : theme.palette.surface.medium,
  },
}));

const SearchTypeActionText = styled(Box)(() => ({
  display: "inline",
  color: theme.palette.text.action.main,
  cursor: "pointer",
}));

const defaultFilters: StartupFilterCriteria = {
  currentInvestmentStage: [],
  isMissingValidation: false,
};

export default function StartupList(): ReactElement {
  const history = useHistory();
  const [refresh, setRefresh] = useState<boolean>(false);
  const [useScroll, setUseScroll] = useState(true);
  const { globalLoader, setGlobalLoader } = useContext(GlobalLoaderContext);
  const { isExternalUser, ventureClientId } = useRoles();
  const [counts, setCounts] = useState<StartupCounts>();
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);
  const [selectedStartupName, setSelectedStartupName] = useState("");
  const [pitchbookModalOpen, setPitchbookModalOpen] = useState(false);
  const [createStartupModalOpen, setCreateStartupModalOpen] = useState(false);

  // States for managing startups and pagination
  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState<number>();
  const [startupsData, setStartupsData] = useState<{
    [key: number]: Startup[];
  }>({});

  const startups = startupsData[page] ?? [];

  // States for managing tabs
  const [activePrimaryTab, setActivePrimaryTab] = useState(0);
  const [activeSecondaryTab, setActiveSecondaryTab] = useState(0);

  const visiblePrimaryTabs = isExternalUser
    ? startupTabs.filter(
        (tab) =>
          tab.allowedVentureClientsIds === "all" ||
          (ventureClientId &&
            tab.allowedVentureClientsIds.includes(ventureClientId))
      )
    : startupTabs;

  const activePrimaryTabTitle = visiblePrimaryTabs[activePrimaryTab].title;
  const activeSecondaryTabTitle =
    visiblePrimaryTabs[activePrimaryTab].subTabs?.[activeSecondaryTab].title;

  // States for managing filters and search
  const [searchValue, setSearchValue] = useState("");
  const [searchType, setSearchType] = useState<"name" | "topic">("name");
  const [filters, setFilters] = useState<StartupFilterCriteria>(defaultFilters);
  const [debouncedSearchValue, setDebouncedSearchValue] = useState("");

  const headerTitleRef = useRef<HTMLElement>(null);

  const topHeaderPadding = parseInt(theme.spacing(10.5));
  const headerPadding = parseInt(theme.spacing(3));
  const noSecondaryTabPadding = visiblePrimaryTabs[activePrimaryTab]?.subTabs
    ?.length
    ? 0
    : 10;

  const [headerMaxHeight, setHeaderMaxHeight] = useState(
    headerTitleRef.current
      ? topHeaderPadding +
          headerPadding +
          headerTitleRef.current.getBoundingClientRect().height
      : 0
  );

  useEffect(() => {
    const updateHeight = () => {
      setHeaderMaxHeight(
        headerTitleRef.current
          ? topHeaderPadding +
              headerPadding +
              headerTitleRef.current.getBoundingClientRect().height
          : 0
      );
    };

    if (!headerTitleRef.current) return;

    updateHeight();

    const resizeObserver = new ResizeObserver(() => {
      updateHeight();
    });

    resizeObserver.observe(headerTitleRef.current);

    return () => resizeObserver.disconnect();
  }, [headerTitleRef.current]);

  const handleSearch = (value: string) => {
    setSearchValue(value);
    setGlobalLoader(true);
    debouncedStateChange(value);
  };

  const handleFilters = (filters: StartupFilterCriteria) => {
    setFilters(filters);
    setPage(1);
  };

  useEffect(() => {
    let ignoreRequest = false;
    async function refreshData() {
      if (useScroll) window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      setUseScroll(true);
      setGlobalLoader(true);
      await StartupHttpService.getStartups(
        filters,
        page,
        searchValue,
        activePrimaryTabTitle,
        activeSecondaryTabTitle,
        searchType,
        visiblePrimaryTabs[activePrimaryTab].featuredListId
      )
        .then((res) => {
          if (!ignoreRequest) {
            setStartupsData(res.data);
            setTotalPage(res.totalPages);
            setCounts(res.counts);
            setGlobalLoader(false);
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, { variant: "error" });
          setGlobalLoader(false);
        });
    }

    refreshData();

    return () => {
      ignoreRequest = true;
    };
  }, [
    filters,
    debouncedSearchValue,
    refresh,
    activePrimaryTabTitle,
    activeSecondaryTabTitle,
    searchType,
  ]);

  useEffect(() => {
    let ignoreRequest = false;
    let loading = false;
    async function refreshData() {
      if (useScroll) window.scrollTo({ behavior: "smooth", left: 0, top: 0 });
      setUseScroll(true);

      if (!startupsData[page]) setGlobalLoader(true);
      loading = true;
      await StartupHttpService.getStartups(
        filters,
        page,
        searchValue,
        activePrimaryTabTitle,
        activeSecondaryTabTitle,
        searchType,
        visiblePrimaryTabs[activePrimaryTab].featuredListId
      )
        .then((res) => {
          if (!ignoreRequest) {
            setStartupsData(res.data);
            setGlobalLoader(false);
            loading = false;
            setTotalPage(res.totalPages);
            setCounts(res.counts);
          }
        })
        .catch((error) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(message, { variant: "error" });
        });
    }

    if (Object.values(startupsData).length) {
      refreshData();
    }

    return () => {
      if (!loading) {
        ignoreRequest = true;
      }
    };
  }, [page]);

  useEffect(() => {
    document.title = `Startups - Venture Client Platform`;
  }, []);

  const debouncedStateChange = useCallback(
    debounce((searchVal: string) => {
      unstable_batchedUpdates(() => {
        setDebouncedSearchValue(searchVal);
        setPage(1);
      });
    }, 500),
    []
  );

  const handleStartupImportAndCreate = async (startupId: number) => {
    history.push(`/startups/${startupId}`);
  };

  const handleStartupAddedToProject = () => {
    setUseScroll(false);
    setRefresh((prevState) => !prevState);
  };

  const handleChangePrimaryTab = (step: number) => {
    setActivePrimaryTab(step);
    setActiveSecondaryTab(0);
    setPage(1);
  };

  const handleChangeSecondaryTab = (step: number) => {
    setPage(1);
    setActiveSecondaryTab(step);
  };

  const handleChangeSearchType = (type: "name" | "topic") => {
    setSearchType(type);
  };

  return (
    <Fragment>
      <PageHeader
        title="Find leading startups."
        subtitle="Learn about startups and their Venture Client activities."
        headerTitleRef={headerTitleRef}
      />
      <ContentWrapper sx={{ pt: 5, isolation: "isolate" }}>
        <StyledHeader>
          <StyledSearchBar
            inputProps={{ "data-testid": "startup-search" }}
            variant="standard"
            value={searchValue}
            onChange={(e) => handleSearch(e.target.value)}
            InputProps={{
              disableUnderline: true,
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton size="small" sx={{ pointerEvents: "none" }}>
                    <SearchIcon color={searchValue ? "primary" : "action"} />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <StyledEndAdornment position="start">
                  {debouncedSearchValue && (
                    <IconButton
                      size="small"
                      onClick={() => handleSearch("")}
                      id="clear-search"
                    >
                      <CloseIcon />
                    </IconButton>
                  )}
                  <Divider
                    orientation="vertical"
                    sx={{ height: theme.spacing(3.5) }}
                  />
                  <SearchTypeButton
                    $selected={searchType === "name"}
                    onClick={() => setSearchType("name")}
                  >
                    Name
                  </SearchTypeButton>
                  <SearchTypeButton
                    $selected={searchType === "topic"}
                    onClick={() => setSearchType("topic")}
                    data-testid="search-type-topic"
                  >
                    Topic
                  </SearchTypeButton>
                </StyledEndAdornment>
              ),
              sx: { borderRadius: theme.shape.radius.full },
            }}
            placeholder="Search a startup"
          />
          <Button
            id="startup-list-filter-button"
            onClick={() => setFilterDrawerOpen(true)}
          >
            <FilterList sx={{ mr: 1 }} /> Filter
          </Button>
          {!isExternalUser && (
            <AddStartup
              variant="contained"
              onClick={() => setPitchbookModalOpen(true)}
            >
              Add Startup
            </AddStartup>
          )}
        </StyledHeader>
        {searchValue && (
          <Stack gap={1} mb={2} color="text.mediumEmphasis">
            <Typography>
              Showing results for
              <Box color="text.primary" display="inline">
                {" "}
                {searchType === "name"
                  ? "startups with names matching"
                  : "startups associated with the topic"}
                <strong>{` "${searchValue}"`}</strong>
              </Box>
            </Typography>
            <Typography variant="body2">
              Search instead for{" "}
              <SearchTypeActionText
                onClick={() =>
                  handleChangeSearchType(
                    searchType === "name" ? "topic" : "name"
                  )
                }
              >
                {searchType === "name"
                  ? "startups associated with the topic"
                  : "startups with names matching"}
                {` "${searchValue}"`}
              </SearchTypeActionText>
            </Typography>
          </Stack>
        )}
        <Box
          position="sticky"
          bgcolor={theme.palette.background.default}
          top={
            headerMaxHeight -
            PRIMARY_HEADER_OFFSET +
            noSecondaryTabPadding +
            "px"
          }
          pt={2}
          zIndex={2}
        >
          <PrimaryTab
            tabs={visiblePrimaryTabs}
            activeTab={activePrimaryTab}
            handleChangeTab={handleChangePrimaryTab}
            headerHeight={headerMaxHeight}
            fullWidth={!visiblePrimaryTabs[activePrimaryTab]?.subTabs?.length}
          />
        </Box>
        <Divider />

        {visiblePrimaryTabs[activePrimaryTab].subTabs && (
          <Box
            position="sticky"
            zIndex={3}
            top={headerMaxHeight - SECONDARY_HEADER_OFFSET + "px"}
            bgcolor={theme.palette.background.default}
          >
            <SecondaryTab
              tabs={visiblePrimaryTabs[activePrimaryTab].subTabs}
              handleChangeTab={handleChangeSecondaryTab}
              activeTab={activeSecondaryTab}
              headerHeight={headerMaxHeight}
              counts={counts}
            />
          </Box>
        )}

        <Box
          id="startup-list"
          display="grid"
          boxSizing="border-box"
          gap={3}
          mt={5}
          gridTemplateColumns="repeat(auto-fill,minmax(max(200px,(100%)/7), 1fr))"
        >
          {globalLoader ? (
            <>
              <Skeleton height={330} />
              {[...Array(18)].map((_, index) => (
                <Skeleton key={index} height={330} />
              ))}
            </>
          ) : (
            startups.map((startup) => {
              return (
                <StartupCard
                  startup={startup}
                  key={startup.id}
                  onStartupAddedToProject={handleStartupAddedToProject}
                  showProjectCounter
                />
              );
            })
          )}
        </Box>
        {startups.length > 0 && (
          <Pagination
            page={page}
            sx={{
              marginBlock: 5,
              display: "flex",
              justifyContent: "flex-end",
              "& .MuiPaginationItem-root": {
                "&.Mui-selected": {
                  color: theme.palette.background.paper,
                  background: theme.palette.brand.accessibility,
                },
              },
            }}
            size="medium"
            data-testid="pagination-startuplist"
            count={totalPage}
            onChange={(_, page) => setPage(page)}
            siblingCount={2}
            shape="rounded"
          />
        )}
        {startups.length < 1 && searchValue && (
          <Stack mt={1} alignItems="center" textAlign="center">
            <RocketLaunchOutlined sx={{ color: theme.palette.text.disabled }} />
            <Typography color={theme.palette.text.disabled}>
              {`${
                searchType === "name"
                  ? "No startups found with names matching"
                  : "No startups found associated with the topic"
              } "${searchValue}"`}
            </Typography>
          </Stack>
        )}
        <FilterDrawer
          open={filterDrawerOpen}
          setFilterDrawerOpen={(state) => setFilterDrawerOpen(state)}
          handleFilters={handleFilters}
          activeFilters={filters}
        />
        {pitchbookModalOpen && (
          <PitchbookSearchModal
            startupName={searchValue}
            pitchbookModalOpen={pitchbookModalOpen}
            handleModalClose={() => setPitchbookModalOpen(false)}
            handleImport={handleStartupImportAndCreate}
            handleCreateStartupModalOpen={(startupName) => {
              setCreateStartupModalOpen(true);
              setSelectedStartupName(startupName);
            }}
          />
        )}
        {createStartupModalOpen && (
          <CreateStartupModal
            startupName={selectedStartupName || searchValue}
            modalOpen={createStartupModalOpen}
            handleModalClose={() => setCreateStartupModalOpen(false)}
            handleCreateResponse={handleStartupImportAndCreate}
          />
        )}
      </ContentWrapper>
    </Fragment>
  );
}
