import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  styled,
} from "@mui/material";
import { ReactElement, useEffect, useState } from "react";
import theme from "../../../../../theme";
import CloseIcon from "@mui/icons-material/Close";
import {
  capitalizeFirstLetter,
  getErrorMessage,
  getStatusColor,
} from "../../../../../utils";
import ProjectHistoryHttpService from "../../../../../Http/ProjectHistory/ProjectHistory.http.service";
import { ProjectHistory } from "../../../../../Types/ProjectHistory";
import { useSnackbar } from "notistack";
import { AxiosError } from "axios";
import ProjectHistoryItem from "./ProjectHistoryItem";
import ScrollableDialogContent from "../../../../UI/Modals/ScrollableDialogContent";

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $statusColor?: string }>(({ $statusColor }) => ({
  color: $statusColor,
  borderColor: $statusColor,
  fontWeight: 500,
  maxWidth: "fit-content",
  padding: "2px 0px",
}));

interface Props {
  projectId: number;
  status: string;
  onHoldDeadline: string | null;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
  setEditStatusModal: (state: boolean) => void;
  setManageCommentModal: (state: boolean) => void;
}

const ProjectStatusOverview = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [projectHistory, setProjectHistory] = useState<ProjectHistory[]>([]);
  const [lastOnHoldHistoryIndex, setLastOnHoldHistoryIndex] = useState(-1);

  useEffect(() => {
    refreshHistory();
  }, []);

  useEffect(() => {
    if (props.status === "on hold")
      setLastOnHoldHistoryIndex(
        projectHistory.findIndex((entry) => entry.newValue === "on hold")
      );
    else setLastOnHoldHistoryIndex(-1);
  }, [projectHistory, props.handleSave]);

  const refreshHistory = () => {
    ProjectHistoryHttpService.getProjectHistory(props.projectId)
      .then((history) => setProjectHistory(history))
      .catch((error) => {
        const errorMessage = getErrorMessage(error as AxiosError);
        enqueueSnackbar(`Could not get the history: ${errorMessage}`, {
          variant: "error",
        });
      });
  };

  const handleAddComment = () => {
    props.setManageCommentModal(true);
    props.setModalOpen(false);
  };

  const handleEditStatus = () => {
    props.setEditStatusModal(true);
    props.setModalOpen(false);
  };

  return (
    <Dialog
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="project-status-overview"
      fullWidth
      maxWidth="md"
      sx={{
        "& .MuiDialog-paper ": {
          padding: theme.spacing(4),
        },
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingInline={1}
      >
        <StatusChip
          size="medium"
          label={capitalizeFirstLetter(props.status)}
          variant="outlined"
          $statusColor={getStatusColor(props.status)}
        />
        <IconButton
          sx={{ color: "icon.primary" }}
          onClick={() => props.setModalOpen(false)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <DialogTitle
        sx={{
          mt: -2.5,
        }}
      >
        Project Updates
      </DialogTitle>
      <ScrollableDialogContent>
        <Stack gap={5}>
          {projectHistory.map((history, index) => (
            <ProjectHistoryItem
              key={history.id}
              handleSave={props.handleSave}
              onHoldDeadline={props.onHoldDeadline}
              history={history}
              refreshHistory={refreshHistory}
              index={index}
              lastOnHoldHistoryIndex={lastOnHoldHistoryIndex}
            />
          ))}
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleEditStatus}
        >
          Edit Status
        </Button>
        <Button
          onClick={handleAddComment}
          variant="contained"
          data-testid="add-comment"
        >
          Add Comment
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProjectStatusOverview;
