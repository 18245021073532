import { Fragment, ReactElement, useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  styled,
  TextField,
} from "@mui/material";
import { SelectOption } from "../../Types/Common";
import theme from "../../theme";

const OptionRow = styled(Box)({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
});
interface Props {
  onChange: (args: SelectOption[] | undefined) => void;
  label: string;
  options: SelectOption[];
  selectedOptions?: SelectOption[] | undefined;
  placeholderSearch?: string;
  disabled?: boolean;
}

export default function CheckboxListWithSearch(props: Props): ReactElement {
  const [isFocused, setIsFocused] = useState(false);
  const placeHolder = props.placeholderSearch || "Name";
  const [internalSelectedOptions, setInternalSelectedOptions] = useState<
    SelectOption[]
  >(props.selectedOptions || []);

  useEffect(() => {
    if (props.selectedOptions) {
      setInternalSelectedOptions(props.selectedOptions);
    }
  }, [props.selectedOptions]);

  const handleOptionClick = (option: SelectOption) => {
    const isSelected = internalSelectedOptions.some(
      (selected) => selected.id === option.id
    );

    const newSelectedOptions = isSelected
      ? internalSelectedOptions.filter((selected) => selected.id !== option.id)
      : [...internalSelectedOptions, option];

    if (!props.selectedOptions) setInternalSelectedOptions(newSelectedOptions);
    props.onChange(newSelectedOptions);
  };

  return (
    <Autocomplete
      sx={{ width: "100%" }}
      multiple
      isOptionEqualToValue={(option: SelectOption, value: SelectOption) =>
        option.id === value.id
      }
      placeholder=""
      onChange={(_, selectedOption) => {
        props.onChange(selectedOption);
      }}
      getOptionLabel={(option) => option.name}
      options={props.options || []}
      noOptionsText="No data found"
      value={internalSelectedOptions}
      disabled={props.disabled}
      limitTags={2}
      renderOption={(props, option) => {
        const isSelected = internalSelectedOptions.some(
          (selected) => selected.id === option.id
        );

        return (
          <Fragment key={option.id}>
            <li
              key={option.id}
              data-testid={`option-${option.name}`}
              {...props}
              style={{
                height: "40px",
                backgroundColor: theme.palette.surface.primary.main,
                color: "black",
              }}
              onClick={() => handleOptionClick(option)}
            >
              <OptionRow>
                <Box flexGrow={1}>{option.name}</Box>
                <Checkbox checked={isSelected} />
              </OptionRow>
            </li>
            <Divider variant="middle" sx={{ width: "100%" }} />
          </Fragment>
        );
      }}
      renderInput={(params) => {
        return (
          <TextField
            {...params}
            fullWidth
            label={props.label}
            placeholder={isFocused ? `Search by ${placeHolder}` : ""}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
              ...params.InputProps,
              sx: {
                "& input.MuiInputBase-input.MuiAutocomplete-input": {
                  minWidth: "auto",
                },
              },
              endAdornment: (
                <Fragment>{params.InputProps.endAdornment}</Fragment>
              ),
            }}
          />
        );
      }}
    />
  );
}
