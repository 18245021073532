import {
  Box,
  Card,
  CardContent,
  Chip,
  Typography,
  Divider,
  styled,
  Link,
  Stack,
} from "@mui/material";
import { ReactElement, useState } from "react";
import { StartupOpportunity } from "../../../../Types/Opportunity";
import {
  getStartupStatus,
  formatDate,
  capitalizeFirstLetter,
} from "../../../../utils";
import ProjectDetailsModal from "../../../UI/Modals/ProjectDetailsModal/ProjectDetailsModal";
import ProjectCardStepper from "./ProjectCardStepper";
import { stageScores } from "../../../../Constants/FunnelStages";
import theme from "../../../../theme";
import StartupRuledOutReason from "./StartupRuledOutReason";

const CardContentWrapper = styled(CardContent)(() => ({
  padding: 0,
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(1),
  "&:last-child": {
    paddingBlock: theme.spacing(2),
  },
}));

const ProjectHeader = styled(Box)(() => ({
  display: "flex",
  gap: theme.spacing(2),
  justifyContent: "space-between",
}));

const ProjectName = styled(Typography)(() => ({
  cursor: "pointer",
  display: "-webkit-box",
  overflow: "hidden",
  WebkitLineClamp: "2",
  textOverflow: "ellipsis",
  WebkitBoxOrient: "vertical",
}));

const StatusChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<{ $statusColor?: string }>(({ $statusColor }) => ({
  ...theme.typography.overline,
  color: $statusColor,
  borderColor: $statusColor,
  height: "fit-content",
  width: "fit-content",
  padding: "2px 0px",
}));

const RuledOutDetails = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  display: "flex",
  padding: theme.spacing(1),
  flexDirection: "column",
  gap: theme.spacing(1),
  borderRadius: theme.shape.radius.minimal,
}));

const getStatusColor = (status: string) => {
  if (status === "Adopted") {
    return theme.palette.success.main;
  } else if (status === "Evaluation") {
    return theme.palette.icon.mediumEmphasis;
  } else if (status === "Ruled Out") {
    return theme.palette.error.main;
  } else if (status === "Selected") {
    return theme.palette.surface.tertiary.dark;
  } else {
    return theme.palette.primary.main;
  }
};

interface ProjectCardProps {
  opportunity: StartupOpportunity;
}

export default function ProjectCard(props: ProjectCardProps): ReactElement {
  const [openDetails, setOpenDetails] = useState(false);
  const project = props.opportunity.project;
  const startupStatus = getStartupStatus(props.opportunity);
  const selectedStartup = project.opportunities.find(
    (o) => o.isSelectedForPilot
  )?.startup;

  const projectStageScore = stageScores[project.funnelStage];

  const ventureClientName = project.businessUnit.ventureClient.name;

  const getRuledOutStage = (opportunity: StartupOpportunity) => {
    const isBeyondDiscover = projectStageScore > stageScores["discover"];
    const isBeyondAssess = projectStageScore > stageScores["assess"];

    if (isBeyondDiscover && !opportunity.isQualified) {
      return `Discover`;
    } else if (isBeyondAssess && !opportunity.isSelectedForPilot) {
      return `Assess`;
    } else return "";
  };

  return (
    <>
      <Card
        elevation={0}
        data-testid={"project-card-" + project.id}
        key={props.opportunity.id}
      >
        <CardContentWrapper>
          <ProjectHeader>
            <Stack gap={0.5}>
              <Stack>
                <ProjectName
                  variant="subtitle2"
                  onClick={() => setOpenDetails(true)}
                >
                  {project.name}
                </ProjectName>
                <Typography variant="body2" color="text.mediumEmphasis">
                  {formatDate(project.dateCreated)}
                </Typography>
              </Stack>
              <Box display="flex" gap={4}>
                <Stack>
                  <Typography variant="body2">{ventureClientName}</Typography>
                  <Typography variant="overline" color="text.mediumEmphasis">
                    Venture Client
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2">
                    {project.businessUnit.name}
                  </Typography>
                  <Typography variant="overline" color="text.mediumEmphasis">
                    Organizational Unit
                  </Typography>
                </Stack>
                <Stack>
                  <Typography variant="body2">
                    {capitalizeFirstLetter(project.status)}
                  </Typography>
                  <Typography variant="overline" color="text.mediumEmphasis">
                    Project Status
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </ProjectHeader>
          <Box display="flex" justifyContent="space-between">
            <ProjectCardStepper
              activeStep={projectStageScore - 1}
              isProjectActive={project.status === "active"}
              ruledOutStage={getRuledOutStage(props.opportunity)}
              badgeColor={getStatusColor(startupStatus)}
            />
            <Stack alignItems="center">
              <StatusChip
                label={startupStatus}
                variant="outlined"
                $statusColor={getStatusColor(startupStatus)}
              />
              {startupStatus === "Ruled Out" && (
                <Typography
                  fontWeight="500"
                  fontSize={theme.typography.overline.fontSize}
                  color={theme.palette.text.secondary}
                >
                  {getRuledOutStage(props.opportunity)}
                </Typography>
              )}
            </Stack>
          </Box>
          {projectStageScore > 0 && startupStatus === "Ruled Out" && (
            <RuledOutDetails>
              <StartupRuledOutReason opportunity={props.opportunity} />
              {projectStageScore > 2 && selectedStartup && (
                <>
                  <Divider flexItem />
                  <Box display="flex" alignItems="center" gap={0.5}>
                    <Typography variant="body2" color="text.mediumEmphasis">
                      Chosen Startup
                    </Typography>
                    <Link
                      href={`/startups/${selectedStartup.id}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <Typography color="text.action.main" variant="button">
                        {selectedStartup.name}
                      </Typography>
                    </Link>
                  </Box>
                </>
              )}
            </RuledOutDetails>
          )}
        </CardContentWrapper>
      </Card>
      {openDetails && (
        <ProjectDetailsModal
          projectId={project.id}
          setModalOpen={setOpenDetails}
          modalOpen={openDetails}
        />
      )}
    </>
  );
}
