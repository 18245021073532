import {
  Button,
  Dialog,
  Typography,
  Box,
  Chip,
  Step,
  Stepper,
  IconButton,
  Stack,
  StepConnector,
  stepConnectorClasses,
  styled,
  StepLabel,
  StepIconProps,
  DialogActions,
} from "@mui/material";
import { ReactElement, useContext, useEffect, useState, useMemo } from "react";
import { Link } from "react-router-dom";
import { funnelStages } from "../../../../Constants/FunnelStages";
import { ProjectHttpService } from "../../../../Http/Project/Project.http.service";
import theme from "../../../../theme";
import { ProjectStartupOpportunityAssociation } from "../../../../Types/Project";
import { useSnackbar } from "notistack";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";
import {
  capitalizeFirstLetter,
  findLogo,
  formatDate,
  getStatusColor,
} from "../../../../utils";
import CloseIcon from "@mui/icons-material/Close";
import { Check } from "@mui/icons-material";
import ContactName from "../../../Contacts/ContactCard/ContactName";
import ClientContactSelect from "../../InputFields/ClientContactSelect";
import RocketLaunchOutlined from "@mui/icons-material/RocketLaunchOutlined";
import {
  hasAccessToVentureClient,
  UserContext,
} from "../../../../Context/UserContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RatingButton from "../../../ProjectDetails/RatingButton";
import RatingContent from "../../../ProjectDetails/RatingContent";
import ProjectMainInformation from "./ProjectMainInformation";
import ScrollableDialogContent from "../ScrollableDialogContent";
import parse from "html-react-parser";

export const ShortDescriptionText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
}));

export const StatusCommentText = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 5,
  WebkitBoxOrient: "vertical",
  "& a": {
    color: theme.palette.text.action.main,
    ...theme.typography.subtitle2,
  },
}));

const UsersWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing(2),
  pointerEvents: "none",
});

export const ContactsWrapper = styled(Box)({
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: theme.spacing(2),
  pointerEvents: "none",
  alignItems: "center",
});

export const ContactBox = styled(Box)({
  height: "100%",
  padding: theme.spacing(2),
  backgroundColor: theme.palette.surface.secondary.main,
});

export const StartupBox = styled(Box)({
  boxShadow: theme.boxShadows[1],
  display: "grid",
  gridTemplateColumns: "1fr 6fr auto",
  gap: theme.spacing(2),
  padding: theme.spacing(2),
  alignItems: "center",
  maxHeight: "106px",
});
interface ProjectDetailsModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  projectId: number;
}

export const ProjectCardConnector = styled(StepConnector)(() => ({
  [`& .${stepConnectorClasses.line}`]: {
    borderColor: "transparent",
  },
}));

const ProjectCardStepIconRoot = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  "& .ProjectCardStepIcon-completedIcon": {
    color: "white",
    fontSize: 15,
    borderRadius: "50%",
    padding: "2px",
    backgroundColor: theme.palette.icon.primary,
  },
  "& .ProjectCardStepIcon-circle": {
    width: 15,
    height: 15,
    borderRadius: "50%",
    border: "1px solid currentColor",
  },
}));

export const ProjectCardStepLabel = styled(StepLabel)(({ theme }) => ({
  "& .MuiStepLabel-label": {
    fontSize: theme.typography.overline.fontSize,
    color: theme.palette.text.primary,
  },
  "& .MuiStepLabel-label.Mui-completed,": {
    color: theme.palette.text.primary,
  },
  "& .Mui-disabled": {
    color: theme.palette.text.disabled,
  },
}));

export function ProjectCardStepIcon(props: StepIconProps): ReactElement {
  const { completed, className } = props;
  return (
    <ProjectCardStepIconRoot className={className}>
      {completed ? (
        <Check className="ProjectCardStepIcon-completedIcon" />
      ) : (
        <div className="ProjectCardStepIcon-circle" />
      )}
    </ProjectCardStepIconRoot>
  );
}

export default function ProjectDetailsModal(
  props: ProjectDetailsModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [project, setProject] =
    useState<ProjectStartupOpportunityAssociation>();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const user = useContext(UserContext);

  const sortedOpportunities = useMemo(() => {
    return project?.opportunities.sort((a, b) => {
      if (a.isSelectedForPilot && !b.isSelectedForPilot) {
        return -1;
      }
      if (!a.isSelectedForPilot && b.isSelectedForPilot) {
        return 1;
      }

      // If both are selected or not selected for pilot, then consider ratings
      if (a.rating !== null && b.rating !== null) {
        if (a.rating === b.rating) {
          return (
            Number(b.startup.totalFunding) - Number(a.startup.totalFunding)
          );
        } else {
          return a.rating.localeCompare(b.rating);
        }
      } else if (a.rating === null && b.rating !== null) {
        return 1;
      } else if (a.rating !== null && b.rating === null) {
        return -1;
      } else {
        return Number(b.startup.totalFunding) - Number(a.startup.totalFunding);
      }
    });
  }, [project?.opportunities]);

  useEffect(() => {
    setGlobalLoader(true);
    ProjectHttpService.getProjectById(props.projectId, "slim")
      .then((project) => {
        setProject(project as ProjectStartupOpportunityAssociation);
      })
      .catch(() => {
        enqueueSnackbar("Something went wrong with opening the project modal", {
          variant: "error",
        });
      })
      .finally(() => {
        setGlobalLoader(false);
      });
  }, []);

  if (!project) {
    return <></>;
  }

  const statusCommentLastModifiedDate = formatDate(
    project.projectHistoryLatestChange[0]?.dateTriggered
  );
  const lastModifiedBy = project.projectHistoryLatestChange[0]?.user.name;
  const isArchived = project.status === "archived";
  const isOnHold = project.status === "on hold";
  const statusComment = project.projectHistoryLatestChange[0].description;
  const onHoldDeadline = project.onHoldDeadline
    ? formatDate(new Date(project.onHoldDeadline))
    : null;
  const statusReason =
    project.projectHistoryLatestChange[0]?.projectStatusChangeReason
      ?.description;

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="related-project-details-modal"
      PaperProps={{
        sx: { maxHeight: "calc(100% - 256px)", gap: theme.spacing(4) },
      }}
    >
      <Stack>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Chip
            label={capitalizeFirstLetter(project.status)}
            variant="outlined"
            sx={{
              maxWidth: "fit-content",
              borderColor: getStatusColor(project.status),
              color: getStatusColor(project.status),
              backgroundColor:
                project.status === "Adopted"
                  ? "surface.success"
                  : "transparent",
            }}
          />
          <IconButton
            sx={{ color: "icon.primary", p: 0 }}
            onClick={() => props.setModalOpen(false)}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Stack>
            <Typography variant="h3">{project.name}</Typography>
            <Box marginBlock={2}>
              <ProjectMainInformation project={project} />
            </Box>
          </Stack>
        </Box>
        <Typography variant="body1" pr={3}>
          {project.shortDescription}
        </Typography>
      </Stack>
      <Stepper
        activeStep={funnelStages.indexOf(project.funnelStage)}
        connector={<ProjectCardConnector />}
        sx={{
          pb: 2,
          borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
        }}
      >
        {funnelStages.map((label, index) => {
          return (
            <Step
              key={label}
              completed={index <= funnelStages.indexOf(project.funnelStage)}
              disabled={true}
              sx={{ p: 0 }}
            >
              <ProjectCardStepLabel
                StepIconComponent={(stepIconProps) =>
                  ProjectCardStepIcon(stepIconProps)
                }
              >
                {capitalizeFirstLetter(label)}
              </ProjectCardStepLabel>
            </Step>
          );
        })}
      </Stepper>
      <ScrollableDialogContent>
        <Stack gap={4} paddingBottom={0.5}>
          {statusComment && (
            <Stack bgcolor={theme.palette.surface.secondary.main} p={2} gap={2}>
              {(isArchived || isOnHold) && (
                <Stack>
                  <Typography variant="subtitle2">
                    Project is {project.status}{" "}
                    {onHoldDeadline ? `until ${onHoldDeadline}` : ""}
                  </Typography>

                  <Typography>
                    <b>Reason: </b>
                    {statusReason}
                  </Typography>
                </Stack>
              )}

              <StatusCommentText variant="body1">
                {parse(statusComment)}
              </StatusCommentText>

              {statusCommentLastModifiedDate && lastModifiedBy && (
                <Stack>
                  <Typography variant="body1">{lastModifiedBy}</Typography>
                  <Typography variant="body2" color="text.mediumEmphasis">
                    Updated on {statusCommentLastModifiedDate}
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
          <Stack gap={1}>
            <Typography variant="subtitle1">Project Administrators</Typography>
            <UsersWrapper>
              <ContactName
                name={project.projectOwner.name}
                title="Project Owner"
                orientation="horizontal"
                isVentureAssociate
              />
              {project.startupIntelligence && (
                <ContactName
                  name={project.startupIntelligence.name}
                  title="Startup Intelligence"
                  orientation="horizontal"
                  isVentureAssociate
                />
              )}
            </UsersWrapper>
          </Stack>

          <Stack gap={1}>
            <Typography variant="subtitle1">Project Team</Typography>
            <ContactsWrapper>
              {project.projectLeader ? (
                <ContactBox>
                  <ClientContactSelect
                    editMode={false}
                    labelText=""
                    fieldId="projectLeaderId"
                    field="projectLeader"
                    ventureClientId={project.businessUnit.ventureClient.id}
                    onChange={() => null}
                    contactData={project.projectLeader}
                  />
                </ContactBox>
              ) : (
                <Typography variant="body1" color="text.disabled">
                  No Project Leader added
                </Typography>
              )}

              {project.programManager ? (
                <ContactBox>
                  <ClientContactSelect
                    editMode={false}
                    labelText=""
                    fieldId="startupIntelligenceId"
                    field="startupIntelligence"
                    ventureClientId={project.businessUnit.ventureClient.id}
                    onChange={() => null}
                    contactData={project.programManager}
                  />
                </ContactBox>
              ) : (
                <Typography variant="body1" color="text.disabled">
                  No Program Manager added
                </Typography>
              )}
            </ContactsWrapper>
          </Stack>

          <Stack gap={1}>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="subtitle1">Assigned Startups</Typography>
              <Chip
                variant="counter"
                color="info"
                label={project.opportunities.length}
              />
            </Box>
            <Stack gap={2} px={0.5}>
              {sortedOpportunities?.map((opportunity) => {
                const logo = findLogo(opportunity.startup.files);
                const startup = opportunity.startup;
                const foundedYear = startup.dateFounded?.getFullYear();
                const isFundingUndisclosed =
                  opportunity.startup.fundingIsUndisclosed ||
                  !opportunity.startup.totalFunding;

                return (
                  <Link
                    target="_blank"
                    key={opportunity.id}
                    to={"/startups/" + opportunity.startup.id}
                  >
                    <StartupBox data-testid={`opportunity-${opportunity.id}`}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        minHeight="100%"
                      >
                        {logo ? (
                          <img
                            src={logo}
                            alt="startup logo"
                            style={{
                              width: theme.spacing(12),
                              height: theme.spacing(10),
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <RocketLaunchOutlined color="disabled" />
                        )}
                      </Box>

                      <Stack gap={0.5}>
                        <Box display="flex" gap={1}>
                          <Typography variant="body2" color="text.disabled">
                            {startup.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.brand.accessibility"
                          >
                            {foundedYear}
                          </Typography>
                        </Box>

                        <ShortDescriptionText variant="body2">
                          {startup.shortDescription}
                        </ShortDescriptionText>

                        <Box display="flex" alignItems="center" gap={2}>
                          <Box display="flex" alignItems="center" gap={0.5}>
                            <Typography
                              variant="overline"
                              color="text.mediumEmphasis"
                            >
                              Funding
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.brand.accessibility"
                            >
                              {isFundingUndisclosed
                                ? "Undisclosed"
                                : `$${startup.totalFunding}M`}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center" gap={0.5}>
                            <Typography
                              variant="overline"
                              color="text.mediumEmphasis"
                            >
                              Stage
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.brand.accessibility"
                            >
                              {startup.currentInvestmentStage || "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                      <Box display="flex" gap={2} alignItems="center">
                        <RatingButton>
                          <RatingContent
                            className={`${opportunity.rating}`}
                            rating={opportunity.rating}
                          >
                            {opportunity.rating}
                          </RatingContent>
                        </RatingButton>
                        {opportunity.isSelectedForPilot && (
                          <CheckCircleIcon
                            sx={{ color: theme.palette.surface.success }}
                          />
                        )}
                      </Box>
                    </StartupBox>
                  </Link>
                );
              })}
            </Stack>
          </Stack>
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        {hasAccessToVentureClient(
          user,
          project.businessUnit.ventureClient.id
        ) && (
          <Button
            variant="contained"
            color="secondary"
            component="a"
            href={`/projects/${project.id}`}
            target="_blank"
            data-testid="view-project-details"
          >
            View Details
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
