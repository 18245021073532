import { RocketLaunchOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { GridCloseIcon } from "@mui/x-data-grid-premium";
import { useSnackbar } from "notistack";
import { ReactElement, useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { GlobalLoaderContext } from "../../../../Context/LoaderContext";
import {
  hasAccessToVentureClient,
  UserContext,
} from "../../../../Context/UserContext";
import { LeadProjectsHttpService } from "../../../../Http/LeadProjects/LeadProjects.http.service";
import theme from "../../../../theme";
import { LeadProject } from "../../../../Types/LeadProject";
import { ProjectLeadAssociation } from "../../../../Types/Project";
import {
  capitalizeFirstLetter,
  findLogo,
  formatDate,
  getStatusColor,
} from "../../../../utils";
import ClientContactSelect from "../../InputFields/ClientContactSelect";
import ScrollableDialogContent from "../ScrollableDialogContent";
import LeadProjectMainInformation from "./LeadProjectMainInformation";
import {
  ContactBox,
  ContactsWrapper,
  ShortDescriptionText,
  StartupBox,
  StatusCommentText,
} from "./ProjectDetailsModal";

const UseCase = styled(Typography)({
  padding: theme.spacing(2, 2, 2, 0),
  borderBottom: `1px solid ${theme.palette.borderOutline.soft}`,
});

const ProjectName = styled(Typography)(() => ({
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  color: theme.palette.text.action.main,
  cursor: "pointer",
  ...theme.typography.subtitle2,
}));

interface ProjectLaunchedProps {
  project: ProjectLeadAssociation;
  ventureClient: string;
}

const ProjectLaunched = (props: ProjectLaunchedProps) => {
  return (
    <Stack
      gap={2}
      p={2}
      bgcolor="background.default"
      borderRadius={theme.shape.radius.minimal}
    >
      <Stack gap={0.5}>
        <ProjectName>{props.project.name}</ProjectName>
        <Typography variant="body2" color="text.mediumEmphasis">
          {formatDate(props.project.dateCreated)}
        </Typography>
      </Stack>
      <Typography variant="body2">{props.project.businessUnit.name}</Typography>
    </Stack>
  );
};

interface LeadProjectDetailsModalProps {
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  leadProjectId: number;
}

export default function LeadProjectDetailsModal(
  props: LeadProjectDetailsModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [leadProject, setLeadProject] = useState<LeadProject>();
  const { setGlobalLoader } = useContext(GlobalLoaderContext);
  const user = useContext(UserContext);

  const sortedOpportunities = useMemo(() => {
    return leadProject?.opportunities.sort((a, b) => {
      return Number(b.startup.totalFunding) - Number(a.startup.totalFunding);
    });
  }, [leadProject?.opportunities]);

  useEffect(() => {
    setGlobalLoader(true);
    LeadProjectsHttpService.getLeadProjectById(props.leadProjectId)
      .then((leadProject) => {
        setLeadProject(leadProject);
      })
      .catch(() => {
        enqueueSnackbar(
          "Something went wrong with opening the lead project modal",
          {
            variant: "error",
          }
        );
      })
      .finally(() => {
        setGlobalLoader(false);
      });
  }, []);

  if (!leadProject) {
    return <></>;
  }

  const statusCommentLastModifiedDate =
    leadProject.lastModifiedDate && formatDate(leadProject.lastModifiedDate);
  const lastModifiedBy = leadProject.lastModifiedBy?.name;
  const statusComment = leadProject.statusComment;

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      onClose={() => props.setModalOpen(false)}
      data-testid="related-lead-project-details-modal"
      PaperProps={{
        sx: {
          maxHeight: "calc(100% - 256px)",
          gap: theme.spacing(4),
        },
      }}
    >
      <Stack gap={1}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Chip
            label={capitalizeFirstLetter(leadProject.status)}
            variant="outlined"
            sx={{
              maxWidth: "fit-content",
              borderColor: getStatusColor(leadProject.status),
              color: getStatusColor(leadProject.status),
              backgroundColor:
                leadProject.status === "Adopted"
                  ? "surface.brand.accessibility"
                  : "transparent",
            }}
          />
          <IconButton
            sx={{ color: "icon.primary", p: 0 }}
            onClick={() => props.setModalOpen(false)}
          >
            <GridCloseIcon fontSize="small" />
          </IconButton>
        </Box>
        <DialogTitle>{leadProject.name}</DialogTitle>
        <LeadProjectMainInformation leadProject={leadProject} />
        <Typography pr={3}>{leadProject.shortDescription}</Typography>
      </Stack>
      <ScrollableDialogContent>
        <Stack gap={4}>
          {statusComment && (
            <Stack bgcolor={theme.palette.surface.secondary.main} p={2} gap={2}>
              <StatusCommentText>{leadProject.statusComment}</StatusCommentText>

              {statusCommentLastModifiedDate && lastModifiedBy && (
                <Stack>
                  <Typography>{lastModifiedBy}</Typography>
                  <Typography variant="body2" color="text.mediumEmphasis">
                    Updated on {statusCommentLastModifiedDate}
                  </Typography>
                </Stack>
              )}
            </Stack>
          )}
          <Stack gap={2}>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="subtitle1">Use Cases</Typography>
              <Chip
                variant="counter"
                color="info"
                label={leadProject.useCases.length}
              />
            </Box>
            {leadProject.useCases.length ? (
              <Stack>
                {leadProject.useCases.map((useCase) => (
                  <UseCase key={useCase.id}>{useCase.name || "--"}</UseCase>
                ))}
              </Stack>
            ) : (
              <Typography color="text.disabled">No Use Cases added</Typography>
            )}
          </Stack>
          <Stack gap={2}>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="subtitle1">Project Leaders</Typography>
              <Chip
                variant="counter"
                color="info"
                label={leadProject.leaders.length}
              />
            </Box>
            <ContactsWrapper>
              {leadProject.leaders.length ? (
                <>
                  {leadProject.leaders.map((leader) => (
                    <ContactBox key={leader.id}>
                      <ClientContactSelect
                        editMode={false}
                        labelText=""
                        fieldId="projectLeaderId"
                        field="projectLeader"
                        ventureClientId={leadProject.ventureClientId}
                        onChange={() => null}
                        contactData={leader.projectLeader}
                      />
                    </ContactBox>
                  ))}
                </>
              ) : (
                <Typography color="text.disabled">
                  No Project Leader added
                </Typography>
              )}
            </ContactsWrapper>
          </Stack>
          <Stack gap={2}>
            <Box display="flex" gap={1} alignItems="center">
              <Typography variant="subtitle1">Startups</Typography>
              <Chip
                variant="counter"
                color="info"
                label={leadProject.opportunities.length}
                data-testid="startups-counter"
              />
            </Box>
            <Stack gap={2}>
              {sortedOpportunities?.map((opportunity) => {
                const logo = findLogo(opportunity.startup.files);
                const startup = opportunity.startup;
                const foundedYear = startup.dateFounded?.getFullYear();
                const isFundingUndisclosed =
                  opportunity.startup.fundingIsUndisclosed ||
                  !opportunity.startup.totalFunding;

                return (
                  <Link
                    target="_blank"
                    key={opportunity.id}
                    to={"/startups/" + opportunity.startup.id}
                  >
                    <StartupBox data-testid={`opportunity-${opportunity.id}`}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                        minHeight="100%"
                      >
                        {logo ? (
                          <img
                            src={logo}
                            alt="startup logo"
                            style={{
                              width: theme.spacing(12),
                              height: theme.spacing(10),
                              objectFit: "contain",
                            }}
                          />
                        ) : (
                          <RocketLaunchOutlined color="disabled" />
                        )}
                      </Box>

                      <Stack gap={0.5}>
                        <Box display="flex" gap={1}>
                          <Typography variant="body2" color="text.disabled">
                            {startup.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            color="text.brand.accessibility"
                          >
                            {foundedYear}
                          </Typography>
                        </Box>

                        <ShortDescriptionText variant="body2">
                          {startup.shortDescription}
                        </ShortDescriptionText>

                        <Box display="flex" alignItems="center" gap={2}>
                          <Box display="flex" alignItems="center" gap={0.5}>
                            <Typography
                              variant="overline"
                              color="text.mediumEmphasis"
                            >
                              Funding
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.brand.accessibility"
                            >
                              {isFundingUndisclosed
                                ? "Undisclosed"
                                : `$${startup.totalFunding}M`}
                            </Typography>
                          </Box>
                          <Box display="flex" alignItems="center" gap={0.5}>
                            <Typography
                              variant="overline"
                              color="text.mediumEmphasis"
                            >
                              Series
                            </Typography>
                            <Typography
                              variant="body2"
                              color="text.brand.accessibility"
                            >
                              {startup.currentInvestmentStage || "N/A"}
                            </Typography>
                          </Box>
                        </Box>
                      </Stack>
                    </StartupBox>
                  </Link>
                );
              })}
            </Stack>
          </Stack>
          {leadProject.projects.length > 0 && (
            <Stack gap={2}>
              <Box display="flex" gap={1} alignItems="center">
                <Typography variant="subtitle1">Projects Launched</Typography>
                <Chip
                  variant="counter"
                  color="info"
                  label={leadProject.projects.length}
                  data-testid="projects-counter"
                />
              </Box>
              {leadProject.projects.map((project) => (
                <ProjectLaunched
                  key={project.id}
                  project={project}
                  ventureClient={leadProject.ventureClient.name}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </ScrollableDialogContent>
      <DialogActions>
        {hasAccessToVentureClient(user, leadProject.ventureClient.id) && (
          <Button
            variant="contained"
            color="secondary"
            component="a"
            href={`/lead-project/${leadProject.id}`}
            target="_blank"
            data-testid="view-lead-details"
          >
            View Details
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
