import {
  Box,
  List,
  Paper,
  Tooltip,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ReactElement, Reducer, useEffect, useReducer, useState } from "react";
import { LeadProject, LeadOpportunity } from "../../../Types/LeadProject";
import { useSnackbar } from "notistack";
import { FileHttpService } from "../../../Http/File/File.http.service";
import {
  File,
  FilesBelongToProjectAndOpportunity,
  FilesClassifiedByProjectAndOpportunityId,
} from "../../../Types/File";
import MapFile from "../../../Http/File/File.mapper";
import { otherFileTypes } from "../../../Constants/Files";
import theme from "../../../theme";
import RefreshOutlinedIcon from "@mui/icons-material/RefreshOutlined";
import AddIcon from "@mui/icons-material/Add";
import { GenerateFileModal } from "../../ProjectDetails/SharedComponents/ProjectOverview/FilesComponent/GenerateFileModal";
import { LeadProjectFileUploadSection } from "./LeadProjectFileUploadSection";
import { LeadOpportunityFilesSection } from "./LeadOpportunityFileSection";
import { FilesListSection } from "../../ProjectDetails/SharedComponents/ProjectOverview/FilesComponent/FilesListSection";

interface LeadProjectFileSectionProps {
  leadProject: LeadProject;
  projectFileTypes?: string[];
  opportunityFileTypes?: string[];
  handleSave: () => void;
}

export function LeadProjectFileSection(
  props: LeadProjectFileSectionProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const [lastUploadFile, setLastUploadFile] = useState<number>(0);
  const [editingFileId, setEditingFileId] = useState<number>();
  const [openGenerateModal, setOpenGenerateModal] = useState(false);
  const [opportunitiesWithFiles, setOpportunitiesWithFiles] = useState<
    LeadOpportunity[]
  >([]);
  const [localFiles, setLocalFiles] = useReducer<
    Reducer<
      {
        [key: string | number]: File[] | undefined;
      },
      Partial<{
        [key: string | number]: File[] | undefined;
      }>
    >
  >((state, newState) => ({ ...state, ...newState }), {});

  const [isFileRefreshing, setIsFileRefreshing] = useState(false);

  const filterFiles = (
    isFileRefreshed = false,
    allFiles: FilesBelongToProjectAndOpportunity = {
      projectFiles: [],
      opportunityFiles: [],
    }
  ) => {
    const files: FilesClassifiedByProjectAndOpportunityId = {};
    const filteredOpportunitiesWithFiles: LeadOpportunity[] = [];
    const projectFiles = isFileRefreshed
      ? allFiles.projectFiles
      : props.leadProject.files;

    files["project"] = filterProjectFiles(projectFiles);

    props.leadProject.opportunities?.forEach((opportunity) => {
      const opportunityFiles = isFileRefreshed
        ? allFiles.opportunityFiles.filter(
            (file) => file.linkableId === opportunity.id
          )
        : opportunity.files;

      const filteredFiles = filterOpportunityFiles(opportunityFiles);

      if (filteredFiles && filteredFiles.length) {
        files[opportunity.id] = filteredFiles;
        filteredOpportunitiesWithFiles.push(opportunity);
      }
    });

    setOpportunitiesWithFiles(filteredOpportunitiesWithFiles);
    setLocalFiles(files);
  };

  useEffect(() => {
    filterFiles();
  }, [props.leadProject.files]);

  useEffect(() => {
    let interval: NodeJS.Timeout;
    if (!editingFileId) {
      interval = setInterval(() => {
        refreshFiles();
      }, 10000);
    }
    return () => {
      clearInterval(interval);
    };
  }, [editingFileId, props.leadProject.opportunities]);

  const filterProjectFiles = (files: File[]): File[] => {
    const projectFiles = files?.filter((file) =>
      props.projectFileTypes?.includes(file.type)
    );
    return MapFile.map(projectFiles);
  };

  const filterOpportunityFiles = (files: File[]): File[] => {
    if (props.opportunityFileTypes) {
      return files.filter((file) =>
        props.opportunityFileTypes?.includes(file.type)
      );
    } else return [];
  };

  const deleteFile = async (fileId: number) => {
    await FileHttpService.delete(fileId);
    props.handleSave();
  };

  const handleFileRename = (
    id: number,
    name: string,
    objectKey: string | number
  ) => {
    const files = JSON.parse(JSON.stringify(localFiles));
    const changedFileSet = files[objectKey];
    if (!changedFileSet) return;

    const changedFile = changedFileSet.find((file: File) => file.id === id);
    if (changedFile) {
      changedFile.name = name;
      setLocalFiles({ [objectKey]: changedFileSet });
    }
  };

  const refreshFiles = async () => {
    setIsFileRefreshing(true);
    try {
      const allFiles = await FileHttpService.getFilesByProjectLeadId(
        props.leadProject.id
      );
      allFiles.opportunityFiles = MapFile.map(allFiles.opportunityFiles);
      allFiles.projectFiles = MapFile.map(allFiles.projectFiles);
      filterFiles(true, allFiles);
      // eslint-disable-next-line
    } catch (error: any) {
      enqueueSnackbar(
        `Something went wrong with refreshing files: ${error.response.data.message}`,
        {
          variant: "error",
        }
      );
    } finally {
      setIsFileRefreshing(false);
    }
  };

  const displayfiles: File[] = localFiles["project"]
    ? localFiles["project"].filter(
        (file: File) => !otherFileTypes.includes(file.type)
      )
    : [];

  const otherFiles: File[] = localFiles["project"]
    ? localFiles["project"].filter((file: File) =>
        otherFileTypes.includes(file.type)
      )
    : [];

  return (
    <Paper sx={{ padding: theme.spacing(4) }}>
      <Box display="flex" flexDirection="column" gap={theme.spacing(2)}>
        <Box justifyContent="space-between" flexDirection="row" display="flex">
          <Typography variant="h6">Files</Typography>
          <Box display="flex" gap={theme.spacing(2)}>
            {!editingFileId && (
              <>
                {isFileRefreshing ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  <Tooltip title="Refresh Files">
                    <RefreshOutlinedIcon
                      color="secondary"
                      fontSize="small"
                      cursor="pointer"
                      data-testid="refresh-files-button"
                      onClick={refreshFiles}
                    />
                  </Tooltip>
                )}
                <Tooltip title="Generate a new document">
                  <AddIcon
                    color="secondary"
                    fontSize="small"
                    cursor="pointer"
                    onClick={() => setOpenGenerateModal(true)}
                    data-testid="open-generate-modal-button"
                  />
                </Tooltip>
              </>
            )}
          </Box>
        </Box>
        {displayfiles.length > 0 && (
          <List dense data-testid="project-files">
            <FilesListSection
              files={displayfiles}
              mapId={"project"}
              deleteFile={deleteFile}
              editingFileId={editingFileId}
              setEditingFileId={setEditingFileId}
              handleFileRename={handleFileRename}
              projectId={props.leadProject.id}
              handleSave={props.handleSave}
            />
          </List>
        )}
        {localFiles && (
          <LeadOpportunityFilesSection
            localFiles={localFiles}
            deleteFile={deleteFile}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            handleFileRename={handleFileRename}
            opportunities={opportunitiesWithFiles}
            lastUploadFileId={lastUploadFile}
            handleSave={props.handleSave}
          />
        )}
        {otherFiles && (
          <FilesListSection
            files={otherFiles}
            mapId="project"
            deleteFile={deleteFile}
            editingFileId={editingFileId}
            setEditingFileId={setEditingFileId}
            handleFileRename={handleFileRename}
            handleSave={props.handleSave}
          />
        )}

        <LeadProjectFileUploadSection
          leadProject={props.leadProject}
          setLastUploadFile={setLastUploadFile}
          opportunityFileTypes={props.opportunityFileTypes || []}
          projectFileTypes={props.projectFileTypes || []}
          handleSave={props.handleSave}
        />
        {openGenerateModal && (
          <GenerateFileModal
            open={openGenerateModal}
            setOpenGenerateModal={setOpenGenerateModal}
            projectId={props.leadProject.id}
            handleSave={props.handleSave}
            stage="lead"
            opportunities={props.leadProject.opportunities}
          />
        )}
      </Box>
    </Paper>
  );
}
