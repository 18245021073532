import {
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  DialogActions,
  DialogContentText,
  Stack,
} from "@mui/material";
import { capitalizeFirstLetter } from "../../../../utils";
import { ReactElement } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import theme from "../../../../theme";
interface Props {
  id: number;
  entity: string;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleDelete: (id: number) => void;
  actionType: "delete" | "remove";
}

const DeleteOrRemoveModal = ({
  id,
  entity,
  modalOpen,
  setModalOpen,
  handleDelete,
  actionType = "delete",
}: Props): ReactElement => {
  const handleAction = () => {
    handleDelete(id);
    setModalOpen(false);
  };

  return (
    <Dialog
      open={modalOpen}
      maxWidth="xs"
      data-testid="delete-modal"
      PaperProps={{
        sx: {
          "&.MuiDialog-paper": {
            p: 0,
            gap: 0,
          },
        },
      }}
    >
      <Stack p={theme.spacing(2, 2, 0)}>
        <DialogTitle
          sx={{ ...theme.typography.subtitle1, justifyContent: "flex-start" }}
        >
          <WarningAmberIcon color="error" />
          {capitalizeFirstLetter(actionType)} {capitalizeFirstLetter(entity)}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            You are about to {actionType} this {entity}. Are you sure you want
            to continue?
          </DialogContentText>
        </DialogContent>
      </Stack>
      <DialogActions sx={{ p: theme.spacing(1) }}>
        <Button onClick={() => setModalOpen(false)}>Cancel</Button>
        <Button
          data-testid={`delete-${id}-button`}
          variant="contained"
          onClick={handleAction}
        >
          {capitalizeFirstLetter(actionType)}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteOrRemoveModal;
