import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  TextField,
} from "@mui/material";
import { ReactElement, Reducer, useReducer } from "react";

import { useSnackbar } from "notistack";
import { LeadProject } from "../../../Types/LeadProject";
import { AxiosError } from "axios";
import { SelectInput } from "../../UI/InputFields/SelectInput";
import {
  ON_HOLD_DEADLINE_TOOLTIP,
  LEAD_PROJECT_STATUS_TOOLTIP,
  LEAD_PROJECT_STATUS_COMMENT_TOOLTIP,
} from "../../../Constants/TooltipText";
import theme from "../../../theme";
import { getErrorMessage } from "../../../utils";
import CustomToolTip from "../../UI/CustomToolTip";
import { LeadProjectsHttpService } from "../../../Http/LeadProjects/LeadProjects.http.service";
import CustomDatePicker from "../../UI/InputFields/CustomDatePicker";
import LeadProjectHistoryHttpService from "../../../Http/LeadProjectHistory/LeadProjectHistory.http.service";
import { LeadProjectHistoryStatusUpdateDTO } from "../../../Types/LeadProjectHistory";

interface Props {
  leadProjectId: number;
  historyId?: number;
  statusComment?: string;
  status: string | null;
  onHoldDeadline?: string | null;
  modalOpen: boolean;
  setModalOpen: (state: boolean) => void;
  handleSave: () => void;
  openAssociatedModal: boolean;
  setLeadProjectStatusOverviewModal: (state: boolean) => void;
  preventStatusChange?: boolean;
}

const EditLeadProjectStatus = (props: Props): ReactElement => {
  const { enqueueSnackbar } = useSnackbar();
  const [leadProject, setLeadProject] = useReducer<
    Reducer<Partial<LeadProject>, Partial<LeadProject>>
  >((state, newState) => ({ ...state, ...newState }), {
    id: props.leadProjectId,
    status: props.status,
    statusComment: props.statusComment,
    onHoldDeadline: props.onHoldDeadline,
  } as Partial<LeadProject>);

  const minDate = new Date();
  minDate.setDate(minDate.getDate() - 1);

  const isActive = leadProject.status === "active";
  const isOnHold = leadProject.status === "on hold";
  const hasComment = !!leadProject.statusComment;
  const statusOptions = ["active", "on hold", "archived"];
  const hasOnHoldDeadline = !!leadProject.onHoldDeadline;
  const currentStatus = props.status;
  const isValidDate = leadProject.onHoldDeadline
    ? new Date(leadProject.onHoldDeadline) > minDate
    : false;

  const showError = (value: string) => {
    enqueueSnackbar(`Please check ${value}`, {
      variant: "error",
    });
  };

  const saveLeadProjectStatus = async () => {
    if (
      isOnHold &&
      (!hasComment ||
        (!hasOnHoldDeadline && !props.preventStatusChange) ||
        (!isValidDate && !props.preventStatusChange))
    )
      return showError("comment and date.");

    if (!isActive && !hasComment) {
      return showError("comment.");
    }

    if (props.preventStatusChange && props.historyId) {
      const updatedHistory: LeadProjectHistoryStatusUpdateDTO = {
        id: props?.historyId,
        leadProjectId: props.leadProjectId,
        description: leadProject.statusComment || "",
        newValue: leadProject.status || null,
        onHoldDeadline: leadProject.onHoldDeadline || "",
      };

      await LeadProjectHistoryHttpService.updateLeadProjectHistory(
        updatedHistory
      )
        .then(() => {
          enqueueSnackbar("Status successfully updated", {
            variant: "success",
          });

          props.setModalOpen(false);
          props.handleSave();
        })
        .catch((error) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(
            `Could not update the project status: ${errorMessage}`,
            {
              variant: "error",
            }
          );
        });
    } else {
      await LeadProjectsHttpService.updateLeadProject(leadProject)
        .then(() => {
          props.handleSave();
          props.setModalOpen(false);
          if (props.openAssociatedModal) {
            props.setLeadProjectStatusOverviewModal(true);
          }
        })
        .catch((error: AxiosError) => {
          const errorMessage = getErrorMessage(error);
          enqueueSnackbar(
            `Could not save the lead project status: ${errorMessage}`,
            {
              variant: "error",
            }
          );
        });
    }
  };

  return (
    <Dialog
      fullWidth
      open={props.modalOpen}
      data-testid="edit-lead-project-status-modal"
    >
      <Stack gap={4}>
        <DialogTitle
          sx={{
            display: "flex",
            justifyContent: "space-between",
            ...theme.typography.h3,
          }}
        >
          Edit Status
        </DialogTitle>
        <Stack gap={4}>
          <Box display="flex" gap={2}>
            <SelectInput
              disabled={props.preventStatusChange}
              id="status"
              label="Status"
              value={leadProject.status}
              onChange={(e) =>
                setLeadProject({
                  status: e.target.value,
                  statusComment: "",
                  onHoldDeadline: undefined,
                })
              }
              toolTipText={LEAD_PROJECT_STATUS_TOOLTIP}
              selectValues={
                statusOptions.map((type) => {
                  return {
                    id: type,
                    name: type,
                  };
                }) || []
              }
              editMode
              required
              fullWidth
            />
            {(currentStatus !== leadProject.status ||
              props.preventStatusChange) && (
              <>
                {(hasOnHoldDeadline || !props.preventStatusChange) &&
                  isOnHold && (
                    <CustomDatePicker
                      editMode={true}
                      label="On Hold until"
                      id="onHoldDeadline"
                      onChange={(value) => {
                        if (value)
                          setLeadProject({ onHoldDeadline: value.toString() });
                      }}
                      value={
                        leadProject.onHoldDeadline
                          ? new Date(leadProject.onHoldDeadline)
                          : null
                      }
                      required
                      toolTipText={ON_HOLD_DEADLINE_TOOLTIP("lead")}
                      disablePast
                    />
                  )}
              </>
            )}
          </Box>
          {(currentStatus !== leadProject.status ||
            props.preventStatusChange) && (
            <TextField
              data-testid="comment-input"
              label={
                <>
                  Comment
                  <CustomToolTip
                    id="statusCommentTooltip"
                    key="statusCommentTooltip"
                    toolTipText={LEAD_PROJECT_STATUS_COMMENT_TOOLTIP}
                    warning={!props.statusComment}
                  />
                </>
              }
              value={leadProject.statusComment || ""}
              onChange={(e) =>
                setLeadProject({ statusComment: e.target.value })
              }
              multiline
              minRows={1}
              required
              error={!leadProject.statusComment && !isActive}
              InputLabelProps={{
                shrink: true,
                sx: {
                  marginTop: "-5px",
                },
              }}
            />
          )}
        </Stack>
        <DialogActions sx={{ mt: 0 }}>
          <Button onClick={() => props.setModalOpen(false)}>Cancel</Button>
          <Button
            onClick={saveLeadProjectStatus}
            variant="contained"
            disabled={
              currentStatus === leadProject.status && !props.preventStatusChange
            }
          >
            Save
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default EditLeadProjectStatus;
