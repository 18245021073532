import { ReactElement, useState, useEffect } from "react";
import {
  Badge,
  Box,
  styled,
  Tab,
  Tabs,
  Tooltip,
  tooltipClasses,
  TooltipProps,
  Typography,
} from "@mui/material";
import ProjectValidator from "../../../../Services/ProjectValidator";
import theme from "../../../../theme";
import { FunnelStage, Project } from "../../../../Types/Project";
import { MissingProjectInformation } from "../../../../Types/ProjectRules";
import { capitalizeFirstLetter } from "../../../../utils";
import { funnelStages, stageScores } from "../../../../Constants/FunnelStages";
import { CheckCircle, RadioButtonUnchecked } from "@mui/icons-material";
import StageQualityModal from "./StageQualityModal";

const WarningTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.surface.primary.main,
    maxWidth: "443px",
    padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    boxShadow: theme.boxShadows[24],
  },
}));

interface Props {
  activeStepIndex: number;
  handleChangeStep: (arg: number) => void;
  funnelStages: FunnelStage[];
  currentStageIndex: number;
  projectData: Project;
}

function StageStepper(props: Props): ReactElement {
  return (
    <Tabs
      value={props.activeStepIndex}
      data-testid="stage-stepper"
      variant="fullWidth"
      sx={{
        "& .MuiTabs-flexContainer": {
          borderBottom: "none",
        },
      }}
    >
      {props.funnelStages.map((funnelStage: FunnelStage, index) => {
        const isHigherStage =
          stageScores[funnelStage] > props.currentStageIndex;
        const days = props.projectData.daysInStages?.[funnelStages[index]];
        const stageName = capitalizeFirstLetter(funnelStage);
        return (
          <Tab
            data-testid={"stage-button-" + stageName}
            sx={{ position: "relative", paddingLeft: 0 }}
            key={funnelStage}
            disabled={props.currentStageIndex < index}
            onClick={() => props.handleChangeStep(index)}
            label={
              <CustomTabLabel
                projectData={props.projectData}
                stageName={stageName}
                currentStageIndex={props.currentStageIndex}
                stepIndex={index}
                days={days || !isHigherStage ? days : undefined}
              />
            }
          />
        );
      })}
    </Tabs>
  );
}

export default StageStepper;

interface CustomTabLabelProps {
  stageName: string;
  projectData: Project;
  currentStageIndex: number;
  stepIndex: number;
  days?: number;
}

function CustomTabLabel(props: CustomTabLabelProps): ReactElement {
  const [missingData, setMissingData] = useState<MissingProjectInformation>();

  useEffect(() => {
    if (stageStarted) {
      const missingInfo = ProjectValidator.getMissingProjectInformation(
        props.projectData,
        props.stageName.toLocaleLowerCase()
      );
      setMissingData(missingInfo);
    }
  }, [props.projectData]);

  const daysDisplay = () => {
    if (props.days) return `${props.days} day${props.days > 1 ? "s" : ""}`;
    return "Not started";
  };

  const stageStarted = props.currentStageIndex >= props.stepIndex;
  const missingDataExists =
    !!missingData?.fields.length || !!missingData?.files.length;

  return (
    <WarningTooltip
      title={<StageQualityModal missingData={missingData} />}
      placement="bottom-start"
      disableHoverListener={!missingDataExists}
    >
      <Box
        display="flex"
        alignSelf="start"
        color={stageStarted ? "text.primary" : "text.disabled"}
        gap={theme.spacing(1)}
      >
        {stageStarted ? (
          <CheckCircle
            htmlColor={theme.palette.icon.tertiaryDark}
            sx={{ fontSize: "18px", marginTop: "3px" }}
          />
        ) : (
          <RadioButtonUnchecked sx={{ fontSize: "18px" }} />
        )}
        <Box display="flex" flexDirection="column" textAlign="start">
          <Typography
            variant="subtitle1"
            data-testid={"stage-title-" + props.stageName}
          >
            <Badge
              variant="dot"
              color="warning"
              invisible={!missingDataExists || !stageStarted}
              sx={{
                "& .MuiBadge-badge": {
                  right: theme.spacing(-1),
                  maxHeight: "6px",
                  minWidth: "6px",
                },
              }}
            >
              {props.stageName}
            </Badge>
          </Typography>
          <Typography
            variant="overline"
            color={stageStarted ? "text.mediumEmphasis" : "text.disabled"}
            textTransform="none"
          >
            {daysDisplay()}
          </Typography>
        </Box>
      </Box>
    </WarningTooltip>
  );
}
