import { ReactElement } from "react";
import AppBar from "@mui/material/AppBar";
import Header from "../UI/Header";
import { styled } from "@mui/system";
import theme from "../../theme";

const StyledAppBar = styled(AppBar)({
  backgroundColor: theme.palette.surface.primary.invert,
  paddingLeft: theme.spacing(9),
});

export default function ApplicationBar(): ReactElement {
  return (
    <StyledAppBar>
      <Header />
    </StyledAppBar>
  );
}
