import {
  DataGridPremium,
  DataGridPremiumProps,
  GridColumnMenu,
  GridColumnMenuProps,
  GridPanel,
  GridPanelProps,
} from "@mui/x-data-grid-premium";
import theme from "../../theme";
import { styled } from "@mui/material";
import { ReactElement } from "react";

const StyledDataGrid = styled(DataGridPremium)(() => ({
  position: "relative",
  border: 0,
  "&.MuiDataGrid-root": {
    ".MuiDataGrid-virtualScroller": {
      gap: theme.spacing(0.5),
      "& .MuiDataGrid-cell:focus-within": {
        outline: "none",
      },
      ".MuiDataGrid-cell": {
        ...theme.typography.body1,
      },
      "& .MuiDataGrid-columnHeaders": {
        "& .MuiDataGrid-columnHeader": {
          color: theme.palette.text.primaryInvert.main,
          background: theme.palette.surface.tertiary.dark,
          outline: "none",
          svg: {
            color: "white",
          },
        },
      },
      "& .MuiDataGrid-virtualScrollerContent  ": {
        ".MuiDataGrid-virtualScrollerRenderZone": {
          gap: theme.spacing(0.5),
          ".MuiDataGrid-row": {
            background: theme.palette.surface.primary.main,
            borderRadius: theme.shape.radius.minimal,
            cursor: "pointer",
            ".MuiDataGrid-cell": {
              display: "flex",
              alignItems: "center",
              border: 0,
            },
          },
        },
      },
      "& .MuiDataGrid-actionsCell": {
        overflow: "hidden",
        maxWidth: "100%",
        ":hover .copy-icon": {
          display: "block",
        },
      },
    },
    ".MuiDataGrid-footerContainer": {
      border: 0,
      marginTop: theme.spacing(0.5),
      background: theme.palette.surface.primary.main,
      borderBottomLeftRadius: theme.shape.radius.minimal,
      borderBottomRightRadius: theme.shape.radius.minimal,
    },
  },
}));

const StyledOverlayText = styled("p")(() => ({
  height: "100%",
  marginTop: 0,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
  backgroundColor: "rgba(245, 245, 245, 0.7)",
}));

function CustomGridPanel(props: GridPanelProps) {
  return (
    <GridPanel
      {...props}
      sx={{
        ...props.sx,
        "&& .Mui-focused": {
          color: theme.palette.text.action.main,
        },
        "& .MuiInput-root::after": {
          borderBottom: "2px solid",
          borderColor: theme.palette.surface.action.main,
        },
      }}
    />
  );
}

interface CustomDataGridProps extends DataGridPremiumProps {
  noRowsText: string;
}
export default function CustomDataGrid(
  props: CustomDataGridProps
): ReactElement {
  const { noRowsText, columns, ...dataGridProps } = props;

  return (
    <StyledDataGrid
      {...dataGridProps}
      columns={columns.map((column) => ({
        align: "left",
        headerAlign: "left",
        ...column,
      }))}
      slots={{
        noRowsOverlay: () => <CustomNoRowsOverlay text={noRowsText} />,
        columnMenu: CustomColumnMenu,
        panel: CustomGridPanel,
      }}
      slotProps={{
        columnMenu: {
          style: {
            boxShadow: theme.boxShadows[8],
          },
        },
      }}
      disableRowSelectionOnClick
    />
  );
}

function CustomNoRowsOverlay(props: { text: string }) {
  return <StyledOverlayText>{props.text}</StyledOverlayText>;
}

function CustomColumnMenu(props: GridColumnMenuProps) {
  return <GridColumnMenu {...props} />;
}
