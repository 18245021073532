import { Typography } from "@mui/material";

// Project/Lead Summary Tooltips
export const NAME_TOOLTIP =
  "This field should contain the name of your project.<br /><br /><B>Do's:</B> Unique keyword combination, enabling others to find your project easily<br /><br /><B>Don'ts:</B> Client-specific information, unnecessary words";
export const SHORT_DESCRIPTION_TOOLTIP =
  "This field should contain a high-level description of the problem at hand on a general level.<br /><br /><B>Do's:</B> Describe the problem on a high level including all most relevant problem features<br /><br /><B>Don'ts:</B> write only keywords, focus on the solution<br /><br /><B>The following questions might be helpful:</B> What is the problem we are trying to solve? Why is this problem relevant? Which technologies are involved?";
export const PROJECT_STATUS_TOOLTIP =
  "This field should display the current status of your project.";
export const PROJECT_STATUS_COMMENT_TOOLTIP =
  "This field should contain an explanation for why your project is set on “on hold” or “archived” and potential next steps.<br />If your project is active, there is no need to fill in this field.";
export const ON_HOLD_DEADLINE_TOOLTIP = (entity: "project" | "lead"): string =>
  `This field defines the deadline on which the 'on hold' status will run out. Upon reaching this date, the ${entity} will return to the status 'active'.`;
export const LEAD_PROJECT_STATUS_TOOLTIP =
  "This field should display the current status of your lead.";
export const LEAD_PROJECT_STATUS_COMMENT_TOOLTIP =
  "This field should contain an explanation for why your lead is set on “on hold” or “archived” and potential next steps.<br />If your lead is active, there is no need to fill in this field.";

// Discover Stage Toottips
export const PROBLEM_DESCRIPTION_TOOLTIP =
  "This field should contain a precise description of your problem. You can use client-specific details including client name, BU, etc.<br /><br /><B>Do's:</B> Full sentences, holistic description of the problem, give context and client-specific information<br /><br /><B>Don'ts:</B> Information that belongs to other fields (e.g. previous projects), use bullet points, describe the solution<br /><br /><B>Guiding questions:</B> What is the problem to be solved? In which context does the problem occur? What challenges are currently faced in solving the problem?";
export const PREVIOUS_PROJECTS_TOOLTIP =
  "This field should report ongoing or previous internal projects.<br /><br /><B>Please provide the following information for all stated projects (if available):</B> description of project scope, date/timeline, project lead, outcome (success or failure + reason)";
export const KNOWN_SOLUTIONS_TOOLTIP =
  "This field should report known solutions from other suppliers (e.g. startups).<br /><br /><B>Do's:</B> Indicate previous experiences and current status<br /><br /><B>Don'ts:</B> Mention internal projects";
export const PROJECT_LEADER_TOOLTIP =
  "This field should contain the name of the person who is responsible for decision-making and will be the pilot lead.";
export const PROJECT_SPONSOR_TOOLTIP =
  "This field should contain the name of the person who provides the budget for the anticipated pilot project.";
export const PROJECT_TEAM_TOOLTIP =
  "This field should contain the names of the additional stakeholders involved during the assessment and evaluation phase.";
export const OTHER_STAKEHOLDERS_TOOLTIP =
  "This field should contain the names of all stakeholders who are mandatory for the adoption success (e.g., IT) or interested in the project.";
export const DEADLINE_TOOLTIP =
  "This field should contain the deadline for the pilot project.";
export const BUDGET_TOOLTIP =
  "This field should contain the budget available for the project. Please also state the composition in case the budget is split between the client and 27pilots.";
export const ASSUMPTIONS_TOOLTIP =
  "This field should contain all underlying assumptions on which your business case calculation is based.";
export const INTANGIBLE_BENEFITS_TOOLTIP =
  "This field should contain all qualitative and non-quantifiable aspects of the potential business impact.";
export const COMMENT_TOOLTIP =
  "This field should contain any information and additional details about the business case you consider essential but existing fields do not capture that.";
export const FIRST_OFFER_TOOLTIP =
  "This field should contain the exact value of the first incoming startup offer in €.<br/>Please indicate the value incl. VAT!";
export const ADDITIONAL_RESOURCES_TOOLTIP =
  "This field should contain all additional resources needed - everything that is needed to run a lean and successful pilot. <br/> For instance, additional hardware, access permission to plant for a startup, involvement personal HR data";
export const PILOT_BACKGROUND_TOOLTIP = `This field should contain a detailed introduction to the problem background a business unit faces (compared to the problem definition where the problem is described in a general way).
  <br/><br/>
  <b>Do's:</b> Focus on the problem background and integrate the additional knowledge you've gained throughout the project
  <br/><br/>
  <b>Don'ts:</b> Only repeat information that is already stated in the problem description
  <br/><br/>
  The following questions might be helpful: What is the problem? What would a solution solve? What is the goal for the long-term adoption?`;
export const LEARNINGS_TOOLTIP =
  "This field should contain all learnings during the whole pilot project. You can use Milestone Meetings or project delays for learnings. Also, you should conduct a SWOT analysis during the pilot project which serves as a basis for this field.";
export const ACHIEVEMENTS_TOOLTIP =
  "This field should contain all achievements during the whole pilot project.";
export const ADOPTION_STATUS_TOOLTIP =
  "This field should contain the current status of the adoption.";
export const ADOPTION_DATE_TOOLTIP =
  "This field should contain the date when the VCL decides to initiate the adoption (e.g. because KPIs are fulfilled) and the adoption fact sheet is filled out.";
export const ADOPTION_RESOURCES_TOOLTIP =
  "This field should contain all additional resources needed - everything that is needed to run a lean and successful adoption.<br/>For instance, additional hardware, access permission to plant for a startup, involvement personal HR data.";
export const ADOPTION_STRATEGY_TOOLTIP = `If already known, the most likely form of adoption should be captured here, as it has a large influence on the sourcing. The strategy might change through the process.
  <br/><br/>
  <b>Acquisition</b>: Sometimes clients already know they want to extend their portfolio through acquisition
  <br/>
  <b>Partnership</b>: Most of the time the initial goal is to do some kind of licensing or (sub-) contracting
  <br/>
  <b>Exploration</b>: Research or Proof-of-Concept projects that are done to get some kind of knowledge without an initial intention of an actual adoption`;
export const KEY_FINDINGS_PDD = `This field should contain all relevant insights from the Problem Deep Dive and will be displayed as part of the management summary in the Problem Deep Dive Report.
  <br/><br/>
  <b>Do's:</b> Full sentences, precise summary of the identified problem and the startup opportunity
  <br/><br/>
  <b>Don'ts:</b> New data, lengthy or detailed descriptions`;
export const RECOMMENDATION_PDD = `This field should contain a precise communication of the recommended next steps and will be displayed as part of the management summary in the Problem Deep Dive Report.
  <br/><br/>
  <b>Do's:</b> A precise statement about our suggested decision <b><i>on whether the project should be continued?</i></b>
  <br/><br/>
  <b>Don'ts:</b> Lengthy or detailed descriptions`;

export const AUTO_GENERATE_PROBLEM_DESCRIPTION = (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      padding: "1em .5em",
    }}
  >
    <span style={{ fontSize: "0.8rem", marginBottom: ".7rem" }}>
      Auto-generate text from notes
    </span>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        textAlign: "left",
      }}
    >
      <b>
        <i>Protect sensitive information:</i>
      </b>
      <span style={{ fontWeight: "400" }}>
        Please use generic placeholders for specific client, product, or brand
        names in your notes
      </span>
    </div>
  </div>
);

export const AUTO_GENERATE_SHORT_DESCRIPTION = (
  <span>
    Auto-generate <br /> Short description from <br /> Problem Description
  </span>
);

export const STATUS_FILTER_TOOLTIP = (
  <Typography>
    You need to have at least
    <br /> one status filter selected.
  </Typography>
);

export const KANBAN_STATUS_FILTER_TOOLTIP = (
  <Typography>
    You cannot remove this status filter. <br /> At least one status filter
    needs to be <br /> selected at all times.
  </Typography>
);

export const HIGHLIGHTED_SUB_TAB = (
  <Typography>
    View startups you have highlighted <br /> by adding them to your Leads.
  </Typography>
);

export const MY_PROJECTS_TOOLTIP = (
  <Typography>
    Shows projects and leads where you&apos;re assigned as the stakeholder for
    Project Owner, Startup Sourcing, Project Leader, Program Manager, or
    Adoption Owner.
  </Typography>
);
