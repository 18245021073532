import { Box, Container, Typography } from "@mui/material";
import { useRef, ReactElement } from "react";
import useClient from "../../Hooks/useClient";
import theme from "../../theme";

const isDevelopment = process.env.NODE_ENV === "development";
const baseS3Url = isDevelopment
  ? "https://vclos-frontend.s3.eu-central-1.amazonaws.com"
  : "https://vclos-frontend-prod.s3.eu-central-1.amazonaws.com";

interface PageHeaderProps {
  title: string;
  subtitle: string;
  headerTitleRef?: React.RefObject<HTMLElement>;
}

function PageHeader(props: PageHeaderProps): ReactElement {
  const listHeaderRef = useRef<HTMLDivElement>(null);
  const { background: clientBackground, hero } = useClient();

  const backgroundUrl =
    hero || clientBackground || baseS3Url + "/hero_banner.png";

  return (
    <>
      <Box
        padding={theme.spacing(3, 0, 0)}
        sx={{
          background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
          backgroundSize: "100% auto",
        }}
        position="sticky"
        top={theme.spacing(-6)}
        zIndex={4}
        justifyContent="space-between"
        display="flex"
        ref={listHeaderRef}
      >
        <Container>
          <Box
            position="relative"
            display="flex"
            justifyContent="space-between"
            width="100%"
            pt={13}
          >
            <Box data-testid="list-header-text">
              <Typography
                variant="h3"
                color="text.primaryInvert.main"
                ref={props.headerTitleRef}
              >
                {props.title}
              </Typography>
            </Box>
          </Box>
        </Container>
      </Box>
      {listHeaderRef.current && (
        <Box
          padding={theme.spacing(1, 0, 3)}
          sx={{
            background: `linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.5)), url(${backgroundUrl}) no-repeat top center fixed`,
            backgroundSize: "100% auto",
          }}
          position="sticky"
          top={
            listHeaderRef.current.getBoundingClientRect().height -
            parseInt(theme.spacing(16))
          }
          zIndex={2}
          justifyContent="space-between"
          display="flex"
          mb={-1}
        >
          <Container>
            <Box
              position="relative"
              display="flex"
              justifyContent="space-between"
              width="100%"
              pb={6}
            >
              <Typography color={theme.palette.text.primaryInvert.main}>
                {props.subtitle}
              </Typography>
            </Box>
          </Container>
        </Box>
      )}
    </>
  );
}

export default PageHeader;
