import React, { useRef, useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { Box, TypographyProps, styled } from "@mui/material";
import theme from "../../theme";

interface TextComponentProps {
  text: string | JSX.Element | JSX.Element[];
  maxLines?: number;
}

interface TextContainerProps extends TypographyProps {
  $expanded: boolean;
  maxlines: number;
}

const TextContainer = styled(Box, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})<TextContainerProps>(({ $expanded, maxlines }) => ({
  overflow: $expanded ? "visible" : "hidden",
  display: $expanded ? "block" : "-webkit-box",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  WebkitLineClamp: $expanded ? "unset" : maxlines,
  transition: "max-height 0.2s ease-in-out",
  whiteSpace: "pre-wrap",
  "& a": {
    color: theme.palette.text.action.main,
  },
}));

const ReadButton = styled(Button)(() => ({
  "&.MuiButton-text": {
    padding: 0,
    backgroundColor: "transparent",
    outline: "none",
  },
}));

const LINE_HEIGHT = 24;

const CustomExpendableText: React.FC<TextComponentProps> = (props) => {
  const { text, maxLines = 5 } = props;
  const textRef = useRef<HTMLSpanElement>(null);
  const [isScrollable, setIsScrollable] = useState<boolean>(false);
  const [expanded, setExpanded] = useState<boolean>(false);

  useEffect(() => {
    const updateScrollability = () => {
      const textContainer = textRef.current;
      if (textContainer) {
        const hasOverflow =
          textContainer.scrollHeight > textContainer.clientHeight;
        setIsScrollable(hasOverflow);
        if (!hasOverflow) {
          setExpanded(false);
        }
      }
    };

    updateScrollability();

    window.addEventListener("resize", updateScrollability);

    return () => {
      window.removeEventListener("resize", updateScrollability);
    };
  }, [text]);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  return (
    <Box>
      <TextContainer
        ref={textRef}
        $expanded={expanded}
        maxlines={maxLines}
        maxHeight={expanded ? "auto" : `${LINE_HEIGHT * maxLines}px`}
      >
        {text}
      </TextContainer>
      {isScrollable && (
        <ReadButton onClick={toggleExpanded} variant="text" color="primary">
          {expanded ? "Read Less" : "Read More"}
        </ReadButton>
      )}
    </Box>
  );
};

export default CustomExpendableText;
