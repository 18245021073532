import { Box, Chip, ChipProps, styled } from "@mui/material";
import { capitalizeFirstLetter, getStatusColor } from "../../utils";
import { ReactElement } from "react";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import theme from "../../theme";

const CustomChip = styled(Chip, {
  shouldForwardProp: (prop: string) => !prop.startsWith("$"),
})(({ $status }: { $status: string }) => ({
  borderColor: getStatusColor($status),
  color: getStatusColor($status),
  "&:hover": {
    cursor: "pointer",
    " & .iconWrapper": {
      maxWidth: "100px",
      marginLeft: theme.spacing(1),
    },
    "& .editIcon": {
      transitionDelay: "0.15s",
      opacity: 1,
    },
  },
}));

const EditIcon = styled(EditOutlinedIcon)(() => ({
  opacity: 0,
  transitionDelay: "0.15s",
  transition: "opacity 0.14s ease-in-out",
  cursor: "pointer",
  fontSize: "12px",
  color: theme.palette.text.action.main,
}));

const IconWrapper = styled(Box)(() => ({
  display: "inline-flex",
  overflow: "hidden",
  maxWidth: 0,
  transition: "max-width 0.3s ease-in-out, margin-left 0.3s ease-in-out",
  marginLeft: 0,
}));

interface Props extends ChipProps {
  status: string;
}

const HoverableStatusChip = ({
  status,
  ...defaultProps
}: Props): ReactElement => {
  return (
    <CustomChip
      {...defaultProps}
      data-testid="status-chip"
      $status={status}
      variant="outlined"
      label={
        <Box display="flex" alignItems="center">
          <span>{capitalizeFirstLetter(status)}</span>
          <IconWrapper className="iconWrapper">
            <EditIcon data-testid="edit-status" className="editIcon" />
          </IconWrapper>
        </Box>
      }
    />
  );
};

export default HoverableStatusChip;
