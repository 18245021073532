import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Stack,
} from "@mui/material";
import { useSnackbar } from "notistack";
import {
  ReactElement,
  Reducer,
  useEffect,
  useReducer,
  useState,
  Fragment,
} from "react";
import { VentureClientHttpService } from "../../Http/VentureClient/VentureClient.http.service";
import { VentureClient } from "../../Types/VentureClient";
import { SelectInput } from "../UI/InputFields/SelectInput";
import { CreateLeadProjectDTO } from "../../Types/LeadProject";
import theme from "../../theme";
import { LeadProjectsHttpService } from "../../Http/LeadProjects/LeadProjects.http.service";
import useRoles from "../../Hooks/useRoles";
import { SourceNamesHttpService } from "../../Http/SourceNames/SourceNames.http.service";
import { SourceName } from "../../Types/SourceName";
import { getErrorMessage } from "../../utils";
import LeadSource from "../LeadProject/LeadProjectSource/LeadSource";

interface CreateLeadProjectModalProps {
  handleModalClose: (createdLeadId?: number) => void;
  modalOpen: boolean;
}

interface newLeadProjectRequiredFields {
  Name: string;
  "Background Information": string;
  "Venture Client"?: string | number;
}

function CreateLeadProjectModal(
  props: CreateLeadProjectModalProps
): ReactElement {
  const { enqueueSnackbar } = useSnackbar();
  const { isExternalUser, ventureClientId } = useRoles();

  const [newLeadProject, setNewLeadProject] = useReducer<
    Reducer<CreateLeadProjectDTO, Partial<CreateLeadProjectDTO>>
  >(
    (state, newState) => ({ ...state, ...newState }),
    {} as CreateLeadProjectDTO
  );
  const [ventureClientSelectOptions, setVentureClientSelectOptions] = useState<
    VentureClient[]
  >([]);
  const [sourceNames, setSourceNames] = useState<SourceName[]>([]);

  useEffect(() => {
    if (!isExternalUser)
      VentureClientHttpService.getVentureClients().then((ventureClients) => {
        setVentureClientSelectOptions(ventureClients);
      });
  }, [isExternalUser]);

  useEffect(() => {
    if (isExternalUser && ventureClientId) {
      setNewLeadProject({ ventureClientId });
    }
  }, []);

  const handleSelectVentureClient = async (ventureClientId: number) => {
    setNewLeadProject({ ventureClientId, sourceNameId: null });
  };

  useEffect(() => {
    const getSourceNamesByVentureClient = async () => {
      await SourceNamesHttpService.getSourceNames(
        newLeadProject.ventureClientId,
        newLeadProject.sourceType
      )
        .then((sourceNames) => setSourceNames(sourceNames))
        .catch((error) => {
          const message = getErrorMessage(error);
          enqueueSnackbar(`Could not update startup details: ${message}`, {
            variant: "error",
          });
        });
    };
    if (newLeadProject.ventureClientId && newLeadProject.sourceType) {
      getSourceNamesByVentureClient();
    }
  }, [newLeadProject.ventureClientId, newLeadProject.sourceType]);

  const newProjectRequiredFields: newLeadProjectRequiredFields = {
    Name: newLeadProject.name,
    "Background Information": newLeadProject.backgroundInformation,
    ...(isExternalUser
      ? {}
      : { "Venture Client": newLeadProject.ventureClientId }),
  };

  const checkRequiredFields = () => {
    const emptyRequiredFieldsArray: string[] = [];
    for (const [key, value] of Object.entries(newProjectRequiredFields)) {
      if (("" + value).trim().length === 0 || !value) {
        emptyRequiredFieldsArray.push(key);
      }
    }
    return emptyRequiredFieldsArray;
  };

  const showError = (value: string) => {
    enqueueSnackbar(`Please enter ${value}`, {
      variant: "error",
    });
  };

  const handleCreateLeadProject = async () => {
    const emptyRequiredFields = checkRequiredFields();
    if (emptyRequiredFields.length > 0) {
      return showError(emptyRequiredFields[0]);
    }
    const createdLeadID = await LeadProjectsHttpService.createLeadProject({
      ...newLeadProject,
      ...(isExternalUser ? { ventureClientId } : {}),
    } as CreateLeadProjectDTO);

    props.handleModalClose(createdLeadID);
  };

  return (
    <Fragment>
      <Dialog
        fullWidth
        open={props.modalOpen}
        data-testid="create-new-lead-project-modal"
        PaperProps={{
          sx: {
            gap: theme.spacing(4),
          },
        }}
      >
        <DialogTitle>Create Lead</DialogTitle>
        <DialogContent>
          <form noValidate autoComplete="off">
            <Stack gap={3}>
              <TextField
                label="Name"
                data-testid="lead-project-name"
                variant="outlined"
                fullWidth
                required
                value={newLeadProject.name || ""}
                onChange={(event) =>
                  setNewLeadProject({ name: event.target.value })
                }
              />

              {!isExternalUser && (
                <SelectInput
                  id="venture-client"
                  label="Venture Client"
                  selectValues={ventureClientSelectOptions}
                  value={newLeadProject.ventureClientId || ""}
                  onChange={(event) =>
                    handleSelectVentureClient(parseInt(event.target.value))
                  }
                  editMode
                  required
                  ignoreValidation
                  fullWidth
                />
              )}

              <TextField
                label="Background Information"
                data-testid="lead-project-background-information"
                fullWidth
                required
                value={newLeadProject.backgroundInformation || ""}
                multiline
                rows={3}
                onChange={(event) =>
                  setNewLeadProject({
                    backgroundInformation: event.target.value,
                  })
                }
              />

              <LeadSource
                leadProject={newLeadProject}
                setNewLeadProject={setNewLeadProject}
                sourceNames={sourceNames}
                setSourceNames={setSourceNames}
              />
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.handleModalClose()}>Cancel</Button>
          <Button
            onClick={handleCreateLeadProject}
            variant="contained"
            data-testid="create-lead-button"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  );
}
export default CreateLeadProjectModal;
